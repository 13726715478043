import {Box} from "@mui/material";
import {CheckCircle, ErrorOutlined} from "@mui/icons-material";
import Lottie from "lottie-react";

import animation from "../../loading.json";

const getSyncStatusText = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = 'Sharing...';
            break;
        case 'OK':
            result = 'Shared';
            break;
        case 'WAITING_LABEL':
            result = 'Waiting for access';
            break;
        case 'WAITING_CONTACTS':
            result = 'Sharing...';
            break;
        default:
            result = 'Waiting for access';
    }
    return result;
}

const getSyncStatusIcon = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = <Box sx={{width: 24, height: 24}}><Lottie animationData={animation} loop/></Box>;
            break;
        case 'OK':
            result = <CheckCircle color={'success'}/>;
            break;
        case 'WAITING_LABEL':
            result = <ErrorOutlined color={'error'}/>;
            break;
        case 'WAITING_CONTACTS':
            result = <Box sx={{width: 24, height: 24}}><Lottie animationData={animation} loop/></Box>;
            break;
        default:
            result = <ErrorOutlined color={'error'}/>;
    }
    return result;
}

export {
    getSyncStatusText,
    getSyncStatusIcon
}
