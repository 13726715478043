import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axiosInstance from "../../api/axiosInstance";

const initialState = {
    status: 'idle',
    error: null,
    manageUsersFilter: {
        status: null,
        license: null
    },
}

export const setGA4ClientId = createAsyncThunk('data/setGA4ClientId', async (payload, {rejectWithValue}) => {
    try {
        const data = {client_id: payload.clientId};
        const response = await axiosInstance(data, 'set_ga_client_id');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setRowSelectionModel: (state, action) => {
            state.rowSelectionModel = action.payload;
        },
        setRowHover: (state, action) => {
            state.rowHover = action.payload;
        },
        setManageUsersFilter: (state, action) => {
            state.manageUsersFilter = action.payload;
        },
        extraReducers(builder) {
            builder
                .addCase(setGA4ClientId.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(setGA4ClientId.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                })
                .addCase(setGA4ClientId.rejected, (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                })
        },
    },
})

export const {
    setManageUsersFilter,
} = dataSlice.actions;

export default dataSlice.reducer
