import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Grid, IconButton, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openAlertDeleteLabelDialog} from "../../store/reducers/dialogSlice";
import {deleteGroups, setDeleteCurrentGroup} from "../../store/reducers/groupsSlice";

export default function AlertDeleteLabelDialog() {
    const groupsInitialized = useSelector((state) => state.groups.groupsInitialized);
    const open = useSelector((state) => state.dialogsOpen.alertDeleteLabel.value);
    const deleteCurrentGroup = useSelector((state) => state.groups.deleteCurrentGroup);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const groups = useSelector((state) => state.groups.groups);
    const checkedLabels = useSelector((state) => state.groups.checkedLabels);
    if (!groupsInitialized) return null;
    const handleClose = () => {
        store.dispatch(setDeleteCurrentGroup(false));
        store.dispatch(openAlertDeleteLabelDialog(false));
    };
    const handleDeleteGroup = async () => {
        if (deleteCurrentGroup) {
            store.dispatch(setDeleteCurrentGroup(false));
            store.dispatch(deleteGroups([{group: currentGroup.resource}]));
        } else {
            store.dispatch(deleteGroups(checkedLabels));
        }
        handleClose();
    };
    let text = '';
    if (deleteCurrentGroup) {
        text = `"${currentGroup?.name}" label?`;
    } else if (checkedLabels.length === 1) {
        const group = groups.find(group => group.resource === checkedLabels[0].group);
        text = `"${group?.name}" label?`;
    } else {
        text = "labels?";
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-label-dialog-title"
            aria-describedby="alert-delete-label-dialog-description"
            PaperProps={{
                sx: {
                    width: 444,
                    maxWidth: 444,
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Delete Label</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    noWrap
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginBottom: 1,
                    }}
                >
                    Are you sure you want to delete {text}
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleDeleteGroup}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Delete</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
