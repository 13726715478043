import {useSelector} from "react-redux";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Fragment} from "react";
import {IconButton, Menu, MenuItem, Tooltip, Typography, Button} from "@mui/material";
import {PersonAddAltOutlined, PeopleAltOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openAddContactDialog, openAddExistingContactsDialog} from "../../store/reducers/dialogSlice";

export default function AddContactsMenu({buttonType = 'icon'}) {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const handleClickAddExistingContacts = (popupState) => {
        popupState.close();
        store.dispatch(openAddExistingContactsDialog(true));
    };
    const handleClickAddNewContact = (popupState) => {
        popupState.close();
        store.dispatch(openAddContactDialog(true));
    };
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <Fragment key="add_contacts">
                    <Tooltip title='Add contacts' placement="bottom">
                        <span>
                            {buttonType === 'icon' ? <IconButton
                                aria-label='Add contacts'
                                color='default'
                                size="medium"
                                {...bindTrigger(popupState)}
                            >
                                <PersonAddAltOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                        color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                />
                            </IconButton> : <Button
                                sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                }}
                                variant="outlined"
                                color={'buttonOutlined'}
                                startIcon={
                                    <PersonAddAltOutlined sx={{width: 18, height: 18}}/>
                                }
                                {...bindTrigger(popupState)}
                            >
                                <Typography variant={'button'} color={'#202124'} sx={{textTransform: "none",}}>
                                    Add contacts
                                </Typography>
                            </Button>}
                        </span>
                    </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                        {currentGroup.resource !== 'contactGroups/myContacts' && <MenuItem onClick={() => {
                            handleClickAddExistingContacts(popupState);
                        }}>
                            <PeopleAltOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    marginRight: 2,
                                }}
                            />
                            <Typography variant={'settingsMenu'}>Add existing contacts</Typography>
                        </MenuItem>}
                        <MenuItem onClick={() => {
                            handleClickAddNewContact(popupState);
                        }}>
                            <PersonAddAltOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    marginRight: 2,
                                }}
                            />
                            <Typography variant={'settingsMenu'}>Add a new contact</Typography>
                        </MenuItem>
                    </Menu>
                </Fragment>
            )}
        </PopupState>
    );
}
