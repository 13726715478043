import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {Box, Grid, Toolbar} from "@mui/material";

export function SkeletonAllLabels() {
    return (
        <Box
            sx={{
                width: '215px',
                marginTop: '22px',
                marginBottom: '8px',
                marginLeft: '26px',
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={36}/>
                </Grid>
                <Grid item md={2.5}>
                    <Box height={40}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={36}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export function SkeletonLabelsPanel() {
    return (
        <Box
            sx={{
                width: '215px',
                marginLeft: '26px',
                marginTop: '8px',
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={36}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={36}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={36}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export function SkeletonLabels() {
    return (
        <Stack spacing={2}
               sx={{
                   width: '215px',
                   marginLeft: '26px',
                   marginTop: '24px',
               }}
        >
            <Skeleton variant="rounded" height={36}/>
            <Skeleton variant="rounded" height={36}/>
            <Skeleton variant="rounded" height={36}/>
            <Skeleton variant="rounded" height={36}/>
            <Skeleton variant="rounded" height={36}/>
            <Skeleton variant="rounded" height={36}/>
        </Stack>
    );
}

export function SkeletonContactsAppBar({DrawerHeader}) {
    return (
        <>
            <DrawerHeader/>
            <Toolbar
                sx={{
                    width: 'auto',
                }}
            >
                <Skeleton variant="rounded" height={36} width={184}/>
                <Box sx={{flexGrow: 1}}/>
                <Box sx={{display: 'flex'}}>
                    <Skeleton variant="rounded" height={36} width={136}/>
                </Box>
            </Toolbar>
        </>
    );
}

export function SkeletonQuantityLicenses() {
    return (
        <Stack
            sx={{
                width: 496,
                marginTop: 4,
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
                <Grid item md={1}>
                    <Box height={40}/>
                </Grid>
                <Grid item md={4}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
            </Grid>
            <Box
                sx={{
                    width: 496,
                    height: 372,
                    marginTop: 3,
                }}
            >
                <Skeleton variant="rounded" height={372}/>
            </Box>
        </Stack>
    );
}
