import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Button, Typography, Container, Box} from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
    return (
        <>
            <Container>
                <StyledContent sx={{textAlign: 'center', alignItems: 'center'}}>
                    <Typography variant="h4" paragraph>
                        Sorry, page not found!
                    </Typography>
                    <Button to="/" size="small" variant="outlined" color={'buttonOutlined'} component={RouterLink}>
                        Shared Contacts Manager Home
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
}
