import {useEffect} from "react";
import {
    CssBaseline,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

// project import
import ThemeProvider from './theme';
import loginRedirect from "./services/loginRedirect";
import Router from "./routes";
import fetchAllData from "./api/fetchAllData";
import store from "./store/store";
import NotificationBar from "./components/Alerts/NotificationBar";
import sockets from "./api/sockets";
import {setSelectedContacts} from "./store/reducers/contactsSlice";

export default function App() {
    const dispatch = useDispatch();
    const needLogin = useSelector((state) => state.user.needLogin);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const contacts = useSelector((state) => state.contacts.contacts);
    useEffect(() => {
        async function fetchData() {
            const result = await fetchAllData(dispatch);
            const getUserResult = result.find(item => item.type === 'user/getUser/fulfilled');
            if (!getUserResult || getUserResult.payload.need_login) return null;
            const socket = sockets(getUserResult.payload.me.socket_token)
            return () => socket.close();
        }

        fetchData();
    }, [dispatch]);
    useEffect(() => {
        async function updateSelectedContacts() {
            if (currentGroup) {
                const resourceSet = new Set(currentGroup.contacts_resources);
                const filteredContacts = contacts.filter((user) => resourceSet.has(user.id));
                store.dispatch(setSelectedContacts({selectedContacts: filteredContacts}));
            }
        }

        updateSelectedContacts();
    }, [currentGroup, contacts, dispatch]);
    if (needLogin) {
        loginRedirect();
    }

    return (
        <ThemeProvider>
            <CssBaseline/>
            <NotificationBar/>
            <Router/>
        </ThemeProvider>
    );
}
