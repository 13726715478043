const usersCountToPlanCount = (usersCount) => {
    const mapping = [
        {'from': 0, 'to': 5},
        {'from': 6, 'to': 10},
        {'from': 11, 'to': 20},
        {'from': 21, 'to': 50},
        {'from': 51, 'to': 100},
        {'from': 101, 'to': 250},
        {'from': 251, 'to': 500},
        {'from': 501, 'to': 1000},
        {'from': 1001, 'to': 2000},
        {'from': 2001, 'to': 3000},
        {'from': 3001, 'to': 5000},
    ];
    return mapping.find(plan => usersCount >= plan.from && usersCount <= plan.to) || mapping[mapping.length - 1];
};
export {usersCountToPlanCount}
