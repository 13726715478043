import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Grid, IconButton, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openAlertRemoveContactFromLabelDialog} from "../../store/reducers/dialogSlice";
import {removeContactsFromGroup, setDeleteCurrentGroup} from "../../store/reducers/groupsSlice";
import {deleteContactsFromGroup} from "../../store/reducers/contactsSlice";

export default function AlertRemoveContactFromLabelDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertRemoveContactFromLabel.value);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const rowSelectionModel = useSelector((state) => state.contacts.rowSelectionModel);
    const handleClose = () => {
        store.dispatch(setDeleteCurrentGroup(false));
        store.dispatch(openAlertRemoveContactFromLabelDialog(false));
    };
    const handleRemove = async () => {
        handleClose();
        const result = await store.dispatch(removeContactsFromGroup({
            groupResource: currentGroup.resource,
            contactsResources: rowSelectionModel
        }));
        store.dispatch(deleteContactsFromGroup(result));
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-label-dialog-title"
            aria-describedby="alert-delete-label-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Remove contacts from label</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    noWrap
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginBottom: 1,
                    }}
                >
                    Are you sure you want to remove "{rowSelectionModel.length}" contacts from the
                    "{currentGroup?.name}" label?
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleRemove}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Delete</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
