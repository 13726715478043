

export const ROLE_SHARE = 'SHARE';
export const ROLE_DELETE = 'DELETE';

export const ROLE_VIEW = 'VIEW';
export const ROLE_EDIT = 'EDIT';


export const USER_STATUS_UNSHARED = 'UNSHARED';
export const USER_STATUS_SHARED_WITH_ME = 'SHARED_WITH_ME';
export const USER_STATUS_SHARED = 'SHARED';
export const USER_STATUS_ALL = 'ALL';


