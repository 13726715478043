const getBillingPeriod = (subscription) => {
    if (Object.keys(subscription).length === 0) return '';
    const planId = subscription.plan_id;
    let result = '';
    switch (planId) {
        case 1:
            result = 'Trial';
            break;
        case 2:
            result = 'Monthly';
            break;
        case 3:
            result = 'Annual';
            break;
        default:
            result = 'Monthly';
    }
    return result;
}

const getPricePerPeriod = (subscription) => {
    if (Object.keys(subscription).length === 0) return '';
    const planId = subscription.plan_id;
    let result = '';
    switch (planId) {
        case 1:
            result = '';
            break;
        case 2:
            result = 'month';
            break;
        case 3:
            result = 'year';
            break;
        default:
            result = 'month';
    }
    return result;
}

const getAmountPeriod = (subscription) => {
    if (Object.keys(subscription).length === 0) return '';
    const planId = subscription.plan_id;
    let result = '';
    switch (planId) {
        case 1:
            result = '';
            break;
        case 2:
            result = 'MONTHLY';
            break;
        case 3:
            result = 'YEAR';
            break;
        default:
            result = 'MONTHLY';
    }
    return result;
}

export {getPricePerPeriod, getBillingPeriod, getAmountPeriod}
