import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Divider, Drawer, Toolbar, Button, Typography} from "@mui/material";

// project import
import {SkeletonAllLabels, SkeletonLabels, SkeletonLabelsPanel} from "../Skeletons";
import {Labels} from "./Labels";
import {AllContactsLabel} from "./AllContacts";
import {LabelsPanel} from "./LabelsPanel";
import store from "../../store/store";
import {openMeetingDialog} from "../../store/reducers/dialogSlice";
import {isNotTrial, isTrialOver} from "../../services/subscriptionStatus";
import SubscriptionStatusAlert from "../Alerts/SubscriptionStatusAlert";


const drawerWidth = 260;

export default function SidebarLabels() {
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const subscription = useSelector((state) => state.user.subscription);
    const [ref, setRef] = useState();
    const [margin, setMargin] = useState(0);
    const TRIAL = subscription.plan_id === 1;
    const isTrialNotOver = (TRIAL && subscription.status !== 0);
    const loading = statusFirstLoad === 'loading';
    useEffect(() => {
        if (ref) {
            setMargin(ref.clientHeight);
        }
    }, [ref]);
    const handleOpenMeetingDialog = () => {
        store.dispatch(openMeetingDialog(true));
    };
    return (
        <Drawer
            variant="permanent"
            PaperProps={{
                style: {
                    overflow: "hidden"
                }
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', border: 0},
            }}
        >
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Box style={{overflowY: "auto", marginBottom: margin}}>
                {loading? <SkeletonAllLabels/> : <AllContactsLabel/>}
                <Divider/>
                {loading ? <SkeletonLabelsPanel/> : <LabelsPanel drawerWidth={drawerWidth}/>}
                {loading ? <SkeletonLabels/> : <Labels drawerWidth={drawerWidth}/>}
            </Box>
            {isTrialNotOver && <Box
                ref={setRef}
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "white",
                    height: 76,
                }}
            >
                <Box
                    mt={2}
                    ml={3}
                >
                    <Button
                        variant='outlined'
                        size="small"
                        ml={2}
                        sx={{
                            width: 121,
                            borderColor: 'rgba(0, 0, 0, 0.54)'
                        }}
                        onClick={handleOpenMeetingDialog}
                    >
                        <Typography variant={'button'}
                                    sx={{textTransform: "none", color: 'rgba(25, 103, 210, 1)'}}>
                            Book a demo
                        </Typography>
                    </Button>
                </Box>
            </Box>}
        </Drawer>
    )
}
