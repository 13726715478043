import { Middleware } from "@reduxjs/toolkit";
import { showNotification } from "../store/reducers/notificationSlice";

export const axiosMiddleware: Middleware =
    ({ dispatch }) =>
        (next) =>
            async (action) => {
                if (action.type.endsWith("/rejected")) {
                    if (action.error?.name === "AbortError") {
                        return next(action);
                    }
                    let errorMessage = 'An error occurred';
                    if (action.payload) {
                        if (action.payload.code !== undefined && action.payload.code === 403) {
                            errorMessage = "You are not authorized for this action.";
                        }
                        if (action.payload.message) {
                            errorMessage = action.payload.message;
                        }
                    }
                    dispatch(
                        showNotification({
                            type: 'error',
                            message: errorMessage,
                        })
                    );
                    dispatch(
                        showNotification({
                            type: 'error',
                            message: errorMessage,
                        })
                    );
                } else if (action.type.endsWith("/fulfilled")) {
                    if (action.payload?.message) {
                        dispatch(
                            showNotification({
                                type: 'success',
                                message: action.payload.message,
                            })
                        );
                    }
                }
                return next(action);
            };
