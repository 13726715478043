import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import StackdriverErrorReporter from 'stackdriver-errors-js';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// project import
import App from './App';
import store from "./store/store";

// ----------------------------------------------------------------------

Bugsnag.start({
    // apiKey: '690772ee694e0bd4805c058de9d69b1d',
    // plugins: [new BugsnagPluginReact()]
    // My Bugsnag Account
    apiKey: 'b9160154ec89f1da47e3aed4cbae8019',
    plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)


const errorHandler = new StackdriverErrorReporter();


window.stackdriverErrorReporter = errorHandler;


window.stackdriverErrorReporter.start({
    key: 'AIzaSyCLfKfgspbdrlNMmhUCpbV-IqocDaWvX_g',
    projectId: 'qualified-world-312212',
})

async function start() {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <App/>
            </Provider>
        </ErrorBoundary>,
    );

}

start()
