import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid, IconButton, Stack,
    TextField,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {CloseOutlined} from "@mui/icons-material";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import parsePhoneNumber from 'libphonenumber-js'

// project import
import store from "../../store/store";
import {setPhoneNumber, updateDomainIsFirstTime} from "../../store/reducers/userSlice";
import {openWelcomeDialog} from "../../store/reducers/dialogSlice";

export default function AskPhoneDialog() {
    const open = useSelector((state) => state.user.domainIsFirstTime);
    const createLabelStatus = useSelector((state) => state.user.setPhoneNumberStatus);
    const loading = createLabelStatus === 'loading';
    const [phoneNumber, savePhoneNumber] = React.useState('')
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const handleChange = (value) => {
        setError(false);
        setHelperText('');
        savePhoneNumber(value);
    };
    const handleClose = () => {
        setError(false);
        setHelperText('');
        store.dispatch(updateDomainIsFirstTime(false));
        store.dispatch(openWelcomeDialog(true));
    };
    const handleSetPhoneNumber = async () => {
        // const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
        // if (!parsedPhoneNumber) {
        //     setError(true);
        //     setHelperText('Wrong phone number format.');
        //     return;
        // }
        // savePhoneNumber(parsedPhoneNumber.number);
        // await store.dispatch(setPhoneNumber(parsedPhoneNumber.number));
        if (phoneNumber.trim() === '') {
            return;
        }
        if (phoneNumber.trim().length > 20) {
            setError(true);
            setHelperText('Max 20 symbols');
            return;
        }
        savePhoneNumber(phoneNumber);
        await store.dispatch(setPhoneNumber(phoneNumber));
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='AskPhoneDialog'
                PaperProps={{
                    sx: {
                        width: 444,
                        maxWidth: 444,
                        minWidth: 444,
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
        >
            <DialogTitle
                sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}
            >
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'}
                            color={'rgba(255, 255, 255, 1)'}>
                            What is your phone number?
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Stack spacing={'16px'} sx={{marginTop: '15px'}}>
                    <Box>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: 16,
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                                marginBottom: 1,
                            }}
                        >
                            Leave your phone number to receive a call from our team, dedicated to helping you achieve
                            your
                            objectives.
                        </Typography>
                    </Box>
                    <TextField
                        error={error}
                        helperText={helperText}
                        onChange={(event) => {
                            handleChange(event.target.value);
                        }}
                        value={phoneNumber}
                        autoFocus
                        margin="dense"
                        id="phone_namber"
                        label="Phone number with a country code"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                            style: {
                                color: error ? 'rgb(211, 47, 47)' : 'rgba(0, 0, 0, 0.54)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        }}
                        inputProps={{
                            sx: {
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    onClick={handleSetPhoneNumber}
                >
                    <Typography variant={'dialogButton'}
                                sx={{
                                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                                }}
                    >Next</Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
