import * as React from 'react';
import {useEffect} from "react";
import {Box, Button, Chip, Divider, Stack, Typography, Grid} from '@mui/material';
import {ModeEditOutlined} from "@mui/icons-material";
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

// project import
import store from "../../store/store";
import {openChangeBillingEmailDialog, openSwitchToAnnualDialog} from "../../store/reducers/dialogSlice";
import LegacyPlanAlert from "../Alerts/LegacyPlanAlert";
import {getPricePerPeriod, getBillingPeriod, getAmountPeriod} from "../../services/getPeriod";
import {calculateAmountChanges, getAmount} from "../../store/reducers/userSlice";
import SwitchToAnnualDialog from "./SwitchToAnnualDialog";

export default function General() {
    const subscription = useSelector((state) => state.user.subscription);
    return (
        <Box sx={{flexGrow: 1}}>
            <Stack spacing={3} sx={{width: 1368}}>
                <LegacyPlanAlert/>
                <Box sx={{width: 1368}}>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box
                                sx={{
                                    width: 440,
                                    height: 136,
                                    flexShrink: 0,
                                    borderRadius: '6px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    background: 'rgba(255, 255, 255, 0.08)',
                                    padding: 3
                                }}
                            >
                                <Stack spacing={1}>
                                    <Typography variant={'settingsMenu'} sx={{width: 200, fontWeight: 500,}}>
                                        Subscription status
                                    </Typography>
                                    <Box>
                                        <Chip
                                            label={
                                                <Typography variant={'label'} sx={{
                                                    fontSize: 14,
                                                    lineHeight: '20px',
                                                    color: subscription && subscription.status !== 0 ? 'rgba(46, 125, 50, 1)' : 'rgba(236, 51, 35, 1)',
                                                }}>{subscription.status !== undefined ? subscription.status !== 0 ? 'Active' : 'Not active' : ''}</Typography>}
                                            size="medium"
                                            variant={'filled'}
                                            color={'primary'}
                                            sx={{
                                                height: '24px',
                                                backgroundColor: subscription.status !== undefined ? subscription.status !== 0 ? 'rgba(46, 125, 50, 0.12)' : 'rgba(236, 56, 40, 0.12)' : 'inherit'
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box
                                sx={{
                                    width: 440,
                                    height: 136,
                                    flexShrink: 0,
                                    borderRadius: '6px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    background: 'rgba(255, 255, 255, 0.08)',
                                    padding: 3
                                }}
                            >
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant={'settingsMenu'} sx={{width: 200, fontWeight: 500}}>
                                            Licenses
                                        </Typography>
                                        <Typography variant={'settingsMenu'}>{subscription.quantity || 0}</Typography>
                                    </Stack>
                                    <ChangeLicenseCountButton/>
                                </Stack>
                            </Box>
                        </Grid>
                        <PriceSection/>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box
                                sx={{
                                    width: 440,
                                    height: 136,
                                    flexShrink: 0,
                                    borderRadius: '6px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    background: 'rgba(255, 255, 255, 0.08)',
                                    padding: 3
                                }}
                            >
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant={'settingsMenu'}
                                                    sx={{width: 200, fontWeight: 500}}>Billing cycle</Typography>
                                        <Typography
                                            variant={'settingsMenu'}>{subscription ? getBillingPeriod(subscription) : ''}</Typography>
                                    </Stack>
                                    <SwitchToAnnualButton/>
                                </Stack>
                            </Box>
                        </Grid>
                        {/* <BillingEmailSection/> */}
                        <NextPaymentSection/>
                    </Grid>
                </Box>
                <Box>
                    <Stack direction="row">
                        <BuySubscriptionButton/>
                        <UpdatePaymentInformationButton/>
                        <Box sx={{flexGrow: 1}}/>
                        <CancelSubscriptionButton/>
                    </Stack>
                </Box>

            </Stack>
        </Box>
    );
}

const ChangeLicenseCountButton = () => {
    const navigate = useNavigate();
    const subscription = useSelector((state) => state.user.subscription);
    if (Object.keys(subscription).length === 0 || subscription.plan_id === 1 || subscription.plan_id === 0 || subscription.status !== 1 || subscription.subscription.subscription_cancel_at) return null;
    return <Box><Link to={''} style={{color: 'rgba(59, 125, 237, 1)'}} onClick={() => navigate("change_subscription")}>
        <Typography
            variant={'settingsMenu'}
            sx={{
                color: 'rgba(59, 125, 237, 1)',
                fontSize: 14,
                lineHeight: '24px',
                fontFeatureSettings: "'clig' off, 'liga' off",
            }}>
            Change license count
        </Typography>
    </Link></Box>
}

const PriceSection = () => {
    const subscription = useSelector((state) => state.user.subscription);
    const amount = useSelector((state) => state.user.amount);
    if (Object.keys(subscription).length === 0) return null;
    if (subscription.status === 2 || subscription.plan_id === 1) return null;
    return <Grid item xs={2} sm={4} md={4}>
        <Box
            sx={{
                width: 440,
                height: 136,
                flexShrink: 0,
                borderRadius: '6px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                background: 'rgba(255, 255, 255, 0.08)',
                padding: 3
            }}
        >
            <Stack spacing={1}>
                <Typography variant={'settingsMenu'} sx={{
                    width: 200,
                    fontWeight: 500,
                }}>Price</Typography>
                <Typography
                    variant={'settingsMenu'}>{subscription.subscription.currency === 'EUR' ? '€' : '$'}{amount.price.toLocaleString() || 0}/{getPricePerPeriod(subscription)}
                </Typography>
            </Stack>
        </Box>
    </Grid>
}

const SwitchToAnnualButton = () => {
    const subscription = useSelector((state) => state.user.subscription);
    if (Object.keys(subscription).length === 0 || subscription.plan_id === 1 || subscription.plan_id === 0 || subscription.status !== 1 || subscription.subscription.subscription_cancel_at) return null;
    if (subscription.plan_id === 3) return null;
    return <Box>
        <Link to={''} style={{color: 'rgba(59, 125, 237, 1)'}}
              onClick={async () => {
                  await store.dispatch(calculateAmountChanges({
                      quantity: subscription.quantity,
                      period: 'YEAR',
                  }));
                  store.dispatch(openSwitchToAnnualDialog(true))
              }}>
            <Typography
                variant={'settingsMenu'}
                sx={{
                    color: 'rgba(59, 125, 237, 1)',
                    fontSize: 14,
                    lineHeight: '24px',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                }}>
                Switch to annual billing (save 30%)
            </Typography>
        </Link>
    </Box>
}

const BillingEmailSection = () => {
    const user = useSelector((state) => state.user);
    const subscription = useSelector((state) => state.user.subscription);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    if (subscription.plan_id === 1 || !subscriptionInitialized || Object.keys(subscription).length === 0 || Object.keys(subscription.subscription).length === 0) return null;
    const originalBillingEmail = subscription.licensed_users?.find(user => user.user_id === subscription.subscription.owner_id).email || null;
    const handleClickChangeBillingEmail = async () => {
        store.dispatch(openChangeBillingEmailDialog(true));
    };
    return <Grid item xs={2} sm={4} md={4}>
        <Box
            sx={{
                width: 440,
                height: 136,
                flexShrink: 0,
                borderRadius: '6px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                background: 'rgba(255, 255, 255, 0.08)',
                padding: 3
            }}
        >
            <Stack spacing={2}>
                <Stack spacing={1}>
                    <Typography variant={'settingsMenu'}
                                sx={{width: 200, fontWeight: 500}}>Billing email</Typography>
                    <Box sx={{width: 300}}>
                        <Typography variant={'settingsMenu'}>
                            {originalBillingEmail || ''}
                        </Typography>
                    </Box>
                </Stack>
                {renderChangeBillingEmailButton(subscription, user.user, handleClickChangeBillingEmail)}
            </Stack>
        </Box>
    </Grid>
}

const NextPaymentSection = () => {
    const user = useSelector((state) => state.user);
    const subscription = useSelector((state) => state.user.subscription);
    return !(subscription?.plan_id === 1 && subscription?.status === 0) &&
        <Grid item xs={2} sm={4} md={4}>
            <Box
                sx={{
                    width: 440,
                    height: 136,
                    flexShrink: 0,
                    borderRadius: '6px',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgba(255, 255, 255, 0.08)',
                    padding: 3
                }}
            >
                <Stack spacing={1}>
                    <Typography variant={'settingsMenu'}
                                sx={{width: 200, fontWeight: 500}}>Next payment</Typography>
                    <Typography
                        variant={'settingsMenu'}>{getNextBillingCaption(user)}</Typography>
                </Stack>
            </Box>
        </Grid>
}

const BuySubscriptionButton = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const subscription = useSelector((state) => state.user.subscription);
    if (subscription.plan_id !== 1) {
        if (subscription.status !== 0) return null;
    } else if (subscription.status === 0) {
        if (!user.domain_admin && user.domain_user) {
            return null;
        }
    }
    return <Button
        variant='contained'
        onClick={() => navigate("change_subscription")}
    >
        <Typography variant={'button'} sx={{textTransform: "none"}}>
            Buy subscription
        </Typography>
    </Button>
}

const RequestNewTrialButton = () => {
    const subscription = useSelector((state) => state.user.subscription);
    if (subscription.plan_id === 1 && subscription.status === 0) {
        return <Button
            component="a"
            variant="outlined"
            color={'buttonOutlined'}
            target="_top"
            rel="noopener noreferrer"
            href={'mailto:info@sharedcontacts.com'}
            sx={{borderColor: 'rgba(0, 0, 0, 0.12)', marginLeft: 1}}
        >
            <Typography variant={'button'} sx={{textTransform: "none", letterSpacing: 0, color: 'rgba(32, 33, 36, 1)'}}>
                Request a new 14-day free trial
            </Typography>
        </Button>
    }
    return null;
}

const UpdatePaymentInformationButton = () => {
    const subscription = useSelector((state) => state.user.subscription);
    if (Object.keys(subscription).length === 0 || (subscription.plan_id === 1 || subscription.plan_id === 0 || subscription.status !== 1 || subscription.subscription.subscription_cancel_at)) return null;
    const handleEditSubscription = () => {
        const Paddle = window.Paddle
        Paddle.Checkout.open({
            override: subscription.subscription.subscription_edit_url
        });
    };
    return <Button
        variant='outlined'
        color={'buttonOutlined'}
        onClick={handleEditSubscription}
        sx={{width: 240, height: 36, borderColor: 'rgba(0, 0, 0, 0.12)'}}
    >
        <Typography variant={'button'} sx={{textTransform: "none", letterSpacing: 0, color: 'rgba(32, 33, 36, 1)'}}>
            Update payment information
        </Typography>
    </Button>
}

const CancelSubscriptionButton = () => {
    const subscription = useSelector((state) => state.user.subscription);
    if (Object.keys(subscription).length === 0 || (subscription.plan_id === 1 || subscription.plan_id === 0 || subscription.status !== 1 || subscription.subscription.subscription_cancel_at)) return null;
    const handleClickCancelSubscription = async () => {
        const Paddle = window.Paddle;
        Paddle.Checkout.open({
            override: subscription.subscription.subscription_cancel_url,
        });
    };
    return <Button
        variant='outlined'
        sx={{width: 173, height: 36}}
        color={"error"}
        onClick={handleClickCancelSubscription}
    >
        <Typography variant={'button'} sx={{textTransform: "none"}}>Cancel subscription</Typography>
    </Button>
}

const renderChangeBillingEmailButton = (subscription, user, handleClickChangeBillingEmail) => {
    if (Object.keys(subscription).length === 0 || (subscription.plan_id === 1 || subscription.plan_id === 0 || subscription.status !== 1 || subscription.subscription.subscription_cancel_at)) return null;
    return <Box><Link to={''} style={{color: 'rgba(59, 125, 237, 1)'}}
                      onClick={handleClickChangeBillingEmail}>
        <Typography
            variant={'settingsMenu'}
            sx={{
                color: 'rgba(59, 125, 237, 1)',
                fontSize: 14,
                lineHeight: '24px',
                fontFeatureSettings: "'clig' off, 'liga' off",
            }}>
            Change billing email
        </Typography>
    </Link></Box>
}

const getNextBillingCaption = (user) => {
    if (user.subscription.plan_id === 1) {
        if (user.subscription.status === 0) return '';
        return `Expires in ${user.subscription.remaining_days} day${user.subscription.remaining_days > 1 ? 's' : ''}`;
    }
    const cancelAt = user.subscription && user?.subscription?.subscription?.subscription_cancel_at;
    if (cancelAt) {
        const cancelPaymentDate = Date.parse(cancelAt);
        const now = new Date();
        const daysDiff = parseInt((cancelPaymentDate - now) / (24 * 3600 * 1000), 10);
        const td = new Date(cancelPaymentDate);
        const dateStr = td.toLocaleDateString("en-US");
        return (
            <Typography variant={'settingsMenu'}>
                Subscription canceled.
                <br/>{daysDiff > 0 ? `Expires in ${daysDiff} day${daysDiff > 1 ? 's' : ''} (${dateStr})` : `Expired ${Math.abs(daysDiff)} day${Math.abs(daysDiff) > 1 ? 's' : ''} ago (${dateStr})`}
            </Typography>
        );
    }
    const nextPaymentDateStr = user.subscription.subscription && new Date(user.subscription.subscription.subscription_next_billing_at);
    if (!nextPaymentDateStr) return '';
    return user.subscription.subscription && `${nextPaymentDateStr.toLocaleString('default', {month: 'long'})} ${nextPaymentDateStr.getDate()}, ${nextPaymentDateStr.getFullYear()}`;
}
