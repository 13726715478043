import {faker} from '@faker-js/faker';
import {sample} from 'lodash';

// ----------------------------------------------------------------------

const invoices = [...Array(42)].map((_, index) => ({
    id: faker.datatype.uuid(),
    date: sample([
        'September 11, 2023',
        'August 11, 2023',
        'July 11, 2023',
        'June 11, 2023',
        'May 11, 2023',
        'April 11, 2023',
        'March 11, 2023',
    ]),
    invoice: sample([
        // `Shared Contacts Manager, ${faker.datatype.uuid()}`,
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
        'Shared Contacts Manager, 7CB76075-0007',
    ]),
    amount: sample([
        '$10',
        '$20',
        '$30',
        '$40',
        '$50',
        '$60',
        '$70',
    ]),
    paymentStatus: sample([
        'Paid on September 11, 2023',
        'Paid on August 11, 2023',
        'Paid on July 11, 2023',
        'Paid on June 11, 2023',
        'Paid on May 11, 2023',
        'Paid on April 11, 2023',
        'Paid on March 11, 2023',
    ]),
    receipt: 'Invoice Receipt'
}));

export default invoices;
