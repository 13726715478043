import * as React from "react";
import {Box, Divider, Typography, Toolbar} from "@mui/material";
import {useSelector} from "react-redux";

// project import
import Header from "../components/Header/Header";
import Users from "../components/ManageUsers/Users";
import ManageUsersAppBar from "../components/ManageUsers/ManageUsersAppBar";
import SidebarManageUsers from "../components/ManageUsers/SidebarManageUsers";
import AddUserToSubscriptionDialog from "../components/ManageUsers/AddUserToSubscriptionDialog";
import AlertDeleteLicenseDialog from "../components/ManageUsers/AlertDeleteLicenseDialog";
import PageNotAvailable from "../components/PageNotAvailable";

export default function ManageUsersPage() {
    return (
        <>
            <Header/>
            <ManageUsersToolbars/>
            <ManageUsersContent/>
            <PageNotAvailable/>
            <AddUserToSubscriptionDialog/>
            <AlertDeleteLicenseDialog/>
        </>
    )
}

const ManageUsersContent = () => {
    const subscription = useSelector((state) => state.user.subscription);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    if (!subscriptionInitialized || subscription.status === 2) return null;
    return (
        <Box sx={{display: 'flex'}}>
            <SidebarManageUsers/>
            <Box component="main" sx={{
                flexGrow: 1,
                marginLeft: 3,
                marginTop: '1px'
            }}>
                <ManageUsersAppBar/>
                <Divider/>
                <Box sx={{marginLeft: 1}}>
                    <Users/>
                </Box>
            </Box>
        </Box>
    )
}

const ManageUsersToolbars = () => {
    const subscription = useSelector((state) => state.user.subscription);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    if (subscription.status !== 2) return null;
    return (
        <>
            <Toolbar/>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Toolbar sx={{height: 72}}>
                <Typography sx={{
                    color: 'rgba(60, 64, 67, 1)',
                    fontSize: 28,
                    fontFamily: "Open Sans",
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.56px',
                }}>
                    Manage Users
                </Typography>
            </Toolbar>
            <Divider/>
        </>
    )
}
