import * as React from "react";
import {Toolbar, Stack, Box, Tab, Typography, Divider} from "@mui/material";
import {useSelector} from "react-redux";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// project import
import Header from "../components/Header/Header";
import InvoicesTable from "../components/ManageSubscription/Invoices/InvoicesTable";
import General from "../components/ManageSubscription/General";
import ChangeBillingEmailDialog from "../components/ManageSubscription/ChangeBillingEmailDialog";
import PageNotAvailable from "../components/PageNotAvailable";
import SwitchToAnnualDialog from "../components/ManageSubscription/SwitchToAnnualDialog";

export default function ManageSubscriptionPage() {
    const subscription = useSelector((state) => state.user.subscription);
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <>
            <Header/>
            <Toolbar/>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Toolbar sx={{height: 72}}>
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontSize: 28,
                        fontFamily: "Open Sans",
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '36px',
                        letterSpacing: '-0.56px',
                    }}
                >
                    Manage Subscription
                </Typography>
            </Toolbar>
            {subscription.status === 2 && <Divider/>}
            <ManageSubscriptionContent value={value} handleChange={handleChange}/>
            <PageNotAvailable/>
            <ChangeBillingEmailDialog/>
            <SwitchToAnnualDialog/>
        </>
    )
}

const ManageSubscriptionContent = ({value, handleChange}) => {
    const subscription = useSelector((state) => state.user.subscription);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    if (!subscriptionInitialized || subscription.status === 2) return null;
    return (
        <Stack
            sx={{
                paddingRight: '36px',
                paddingLeft: '36px',
            }}
        >
            <Box sx={{width: '100%'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="manage_subscription">
                            <Tab label="general" value="1"/>
                            {/* <Tab label="payment information" value="2"/> */}
                            {false && subscription?.status !== 2 && <Tab label="invoices" value="3"/>}
                        </TabList>
                    </Box>
                    <TabPanel value="1"><General/></TabPanel>
                    <TabPanel value="2"><></>
                    </TabPanel>
                    {subscription?.status !== 2 && <TabPanel value="3"><InvoicesTable/></TabPanel>}
                </TabContext>
            </Box>
        </Stack>
    )
}
