import * as React from "react";
import {Stack, Snackbar, Alert, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

// project import
import {hideNotification} from "../../store/reducers/notificationSlice";

const NotificationBar = () => {
    const dispatch = useDispatch();
    const {open, message, type} = useSelector((state) => state.notification);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(hideNotification());
    };

    return (
        <Stack spacing={2} sx={{width: "100%"}}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert
                    onClose={handleClose}
                    severity={type}
                    // sx={{
                    //     width: "100%",
                    //     background: type === 'success' ? 'rgba(46, 125, 50, 1)' : 'rgb(211, 47, 47)',
                    //     "& .MuiAlert-icon": {
                    //         color: 'rgba(255, 255, 255, 1)'
                    //     }
                    // }}
                    // color="warning"
                >
                    <Typography
                        sx={{
                            // color: 'rgba(255, 255, 255, 1)',
                            fontWeight: 500,
                            fontSize: 14,
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            lineHeight: '143%',
                            letterSpacing: '0.15px',
                        }}
                    >
                        {message}
                    </Typography>
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default NotificationBar;
