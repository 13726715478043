import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default function PermissionsSelect({permission, setPermission}) {
    const handleChange = (event) => {
        setPermission(event.target.value);
    };
    return (
        <FormControl
            variant="outlined"
            size='small'
            sx={{width: 160}}
        >
            <InputLabel
                id="demo-simple-select-label"
                sx={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                }}
            >
                Permissions
            </InputLabel>
            <Select
                sx={{
                    color: 'rgba(0, 0, 0, 0.84)',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '23px',
                    letterSpacing: '0.15px',
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission}
                label="Permission"
                onChange={handleChange}
            >
                <MenuItem value={'VIEW'}>View only</MenuItem>
                <MenuItem value={'EDIT'}>Can edit</MenuItem>
                <MenuItem value={'SHARE'}>Can reshare</MenuItem>
            </Select>
        </FormControl>
    );
}
