// Example
// const response = await queue.addRequest(() =>
//      axiosInstance(data, 'get_contacts_and_labels', 'get', signal)
// );

class RequestQueue {
    constructor() {
        this.queue = [];
        this.processing = false;
    }

    addRequest(request) {
        return new Promise((resolve, reject) => {
            this.queue.push({ request, resolve, reject });
            this.processQueue();
        });
    }

    async processQueue() {
        if (this.processing) return;
        this.processing = true;
        while (this.queue.length > 0) {
            const { request, resolve, reject } = this.queue.shift();
            try {
                /* eslint-disable-next-line */
                const response = await request();
                resolve(response);
            } catch (error) {
                reject(error);
            }
        }

        this.processing = false;
    }
}

const queue = new RequestQueue();

export default queue;

