import {configureStore} from '@reduxjs/toolkit';
import {axiosMiddleware} from "../api/middleware";

// project import
import dataReducer from './reducers/dataSlice';
import dialogReducer from './reducers/dialogSlice';
import groupsReducer from './reducers/groupsSlice';
import contactsReducer from './reducers/contactsSlice';
import userReducer from './reducers/userSlice';
import notificationReducer from './reducers/notificationSlice';
import ignorePreviousRequestMiddleware from "../api/ignorePreviousRequestMiddleware";

export default configureStore({
    reducer: {
        data: dataReducer,
        groups: groupsReducer,
        contacts: contactsReducer,
        dialogsOpen: dialogReducer,
        user: userReducer,
        notification: notificationReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(ignorePreviousRequestMiddleware)
            .concat(axiosMiddleware)
})
