import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid, IconButton,
    TextField,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {CloseOutlined} from "@mui/icons-material";
import * as React from "react";

// project import
import store from "../../store/store";
import {renameGroup, setLabelToRename} from "../../store/reducers/groupsSlice";
import {openRenameLabelDialog} from "../../store/reducers/dialogSlice";

export default function RenameLabelDialog() {
    const open = useSelector((state) => state.dialogsOpen.renameLabel.value);
    const groups = useSelector((state) => state.groups.groups);
    const labelToRename = useSelector((state) => state.groups.labelToRename);
    const handleClose = () => {
        setError(false);
        setHelperText('');
        store.dispatch(setLabelToRename({
            resource: labelToRename.resource,
            name: groups.find(group => group.resource === labelToRename.resource).name
        }));
        store.dispatch(openRenameLabelDialog(false));
    };
    const handleRenameGroup = async () => {
        if (labelToRename.name.trim() === '') {
            setError(true);
            setHelperText('Enter name');
            return;
        }
        if (labelToRename.name.trim().length > 255) {
            setError(true);
            setHelperText('Max 255 symbols');
            return;
        }
        const groupWithSameName = groups.find(item => item.name === labelToRename.name);
        if (groupWithSameName && groupWithSameName.resource !== labelToRename.resource) {
            setError(true);
            setHelperText('Label name already exist');
            return;
        }
        if (!groupWithSameName) {
            store.dispatch(renameGroup({resource: labelToRename.resource, name: labelToRename.name}));
        }
        setError(false);
        setHelperText('');
        store.dispatch(openRenameLabelDialog(false));
    };
    const setNameLabelToRename = (value) => {
        store.dispatch(setLabelToRename({resource: labelToRename.resource, name: value}));
    };
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='RenameLabelDialog'
                PaperProps={{
                    sx: {
                        width: 444,
                        maxWidth: 444,
                        minWidth: 444,
                        backgroundColor: 'rgba(59, 125, 237, 1)',
                    }
                }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Rename label</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <TextField
                    error={error}
                    helperText={helperText}
                    autoFocus
                    margin="dense"
                    id="rename_label_name"
                    label="Label Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={labelToRename.name}
                    onChange={(event) => {
                        setNameLabelToRename(event.target.value);
                    }}
                    InputLabelProps={{
                        style: {
                            color: error ? 'rgb(211, 47, 47)' : 'rgba(0, 0, 0, 0.60)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        },
                    }}
                    inputProps={{
                        sx: {
                            color: 'rgba(0, 0, 0, 0.54)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                        }
                    }}
                />
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleRenameGroup}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Rename</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
