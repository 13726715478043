import * as React from "react";
import {Alert, Button, Box} from "@mui/material";
import {FileDownload} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';

// project import
import {getContactsAndLabels} from "../../store/reducers/contactsSlice";
import {updateGroups} from "../../store/reducers/groupsSlice";
import {setDomainInstallAlert} from "../../store/reducers/userSlice";

export default function DomainInstallAlert() {
    const user = useSelector((state) => state.user.user);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const dispatch = useDispatch();
    const handleClickDomainInstall = () => {
        const publicUrl = process.env.REACT_APP_PUBLIC_URL || 'https://sharedcontactsmanager.com/app/';
        const urlStr = `https://workspace.google.com/marketplace/dwi/452164027617?redirect_url=${publicUrl}`;
        const newWindow = window.open(urlStr, "myWindow", 'width=800,height=800');
        const timerCurrent = setInterval(async () => {
            if (newWindow.closed) {
                clearInterval(timerCurrent);
                const groupsAndContacts = await dispatch(getContactsAndLabels());
                dispatch(updateGroups({groups: groupsAndContacts.payload.groups, user}));
            }
        }, 1000);
        dispatch(setDomainInstallAlert(false));
    };
    return (
        domainInstallAlert && <Alert
            sx={{width: '100%', height: '48px', background: '#FDF0E6'}}
            severity="warning"
            action={
                user.domain_admin ?
                    <Button color="inherit" size="small" startIcon={<FileDownload/>}
                            sx={{height: '30px', marginTop: '-1px'}}
                            onClick={handleClickDomainInstall}
                    >
                        Domain install
                    </Button> : ''
            }>
            {user.domain_admin ? <MessageForAdmin/> : <MessageForNotAdmin/>}
        </Alert>
    )
}

const MessageForAdmin = () => {
    return 'To enable all features of Shared Contacts Manager Please install the app across the whole domain.';
}

const MessageForNotAdmin = () => {
    return <>
        To enable all features of Shared Contacts Manager, please ask your <Link
        to="https://support.google.com/a/answer/6208960?hl=en" target={"_blank"} rel="noreferrer"
        style={{
            color: 'rgba(95, 43, 1, 1)',
        }}><Box
        sx={{
            color: 'rgba(95, 43, 1, 1)',
            "&:hover": {
                color: 'rgba(237, 108, 2, 1)',
            },
            display: 'inline',
        }}
    >
        domain administrator
    </Box></Link> to install the app domain-wide.
    </>
};
