import * as React from "react";
import {Toolbar} from "@mui/material";
import {useSelector} from "react-redux";

// project import
import Header from "../components/Header/Header";
import TrialOver from "../components/Trial/TrialOver";

export default function TrialPage() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <>
            <Header/>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <TrialOver/>
        </>
    )

}
