import * as React from "react";
import {Box, Button, Container, Stack, SvgIcon, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

export default function TrialOver() {
    const navigate = useNavigate();
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <Container disableGutters sx={{width: '400px'}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: domainInstallAlert ? 'calc(100vh - 64px - 48px)' : 'calc(100vh - 64px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SvgIcon sx={{width: 96, height: 96, marginBottom: 2}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                        <rect width="96" height="96" rx="48" fill="#FFC6C2"/>
                        <path
                            d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16Z"
                            fill="white"/>
                        <path
                            d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16ZM58.528 63.072L44.8 49.312V32H51.2V46.688L63.072 58.56L58.528 63.072Z"
                            fill="#E43E2B"/>
                    </svg>
                </SvgIcon>
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 28,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.56px',
                    }}>Your trial is over</Typography>
                <Typography
                    sx={{
                        textIndent: '12px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 17,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.34px',
                    }}>To continue using our service, buy a new <br/>subscription plan or request a new free
                    trial.</Typography>
                <Box>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            width: '100%',
                            marginTop: 2,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Button
                            variant='contained'
                            size="medium"
                            onClick={() => navigate("/manage_subscription")}
                        >
                            <Typography variant={'button'} sx={{textTransform: "none"}}>Buy now</Typography>
                        </Button>
                        {/* <Button
                            variant={'outlined'}
                            color="inherit"
                            size="medium"
                            sx={{
                                borderColor: 'rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Typography variant={'button'} sx={{color: 'rgba(32, 33, 36, 1)', textTransform: "none"}}>Request
                                new trial</Typography>
                        </Button> */}
                    </Stack>
                </Box>
            </Stack>
        </Container>

    )
}
