const abortController = {};

const abortPreviousRequest = (requestId, actionType) => {
    if (abortController[actionType]) {
        abortController[actionType].abort('Previous request aborted due to a new request');
    }
    abortController[actionType] = new AbortController();
    return abortController[actionType].signal;
}

export {
    abortPreviousRequest,
}
