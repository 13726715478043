import * as React from "react";
import {Breadcrumbs, Divider, IconButton, Link, Toolbar, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {NavigateNext} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';

// project import
import Header from "../components/Header/Header";
import {ChangeLicenseCount} from "../components/ManageSubscription/ChangeLicenseCount";
import PageNotAvailable from "../components/PageNotAvailable";

export default function ManageSubscriptionPage() {
    const navigate = useNavigate();
    const subscription = useSelector((state) => state.user.subscription);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <>
            <Header/>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Toolbar>
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small"/>}>
                    <Link underline="hover" color="inherit" onClick={() => navigate("/")}
                          sx={{cursor: 'pointer'}}>
                        Shared Contacts Manager
                    </Link>
                    <Link underline="hover" color="inherit" onClick={() => navigate("/manage_subscription")}
                          sx={{cursor: 'pointer'}}>
                        Manage Subscription
                    </Link>
                    <Typography color="text.primary">Change subscription</Typography>
                </Breadcrumbs>
            </Toolbar>
            <Toolbar sx={{height: 72}}>
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontSize: 28,
                        fontFamily: "Open Sans",
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '36px',
                        letterSpacing: '-0.2px',
                    }}
                >
                    Change subscription
                </Typography>
            </Toolbar>
            <Divider/>
            {subscription.status !== 2 ? <ChangeLicenseCount/> : <PageNotAvailable/>}
        </>
    )
}
