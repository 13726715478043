import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openInvitationPending} from "../../store/reducers/dialogSlice";

export default function InvitationPendingDialog() {
    const open = useSelector((state) => state.dialogsOpen.invitationPending.value);
    const invitationPendingEmail = useSelector((state) => state.groups.invitationPendingEmail);
    const handleClose = () => {
        store.dispatch(openInvitationPending(false));
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="invitation-pending-dialog-title"
            aria-describedby="invitation-pending-dialog-description"
            PaperProps={{
                sx: {
                    width: 720,
                    maxWidth: 720,
                    minWidth: 720,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Invitation pending</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Stack spacing={1}>
                    <Stack spacing={2}>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: 16,
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                            }}
                        >
                            An email was sent to <Box component="span" fontWeight='600'
                                                      color={'rgba(0, 0, 0, 1)'}>{invitationPendingEmail || ''}</Box> inviting them to
                            collaborate with your contacts, but it
                            hasn't been
                            accepted yet.
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: 16,
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                            }}
                        >
                            You can resend the invitation by clicking on the button below:
                        </Typography>
                    </Stack>
                    <Button onClick={handleClose} variant={'contained'} sx={{width: 123}}>
                        <Typography
                            sx={{
                                color: '#FFFFFF',
                                fontSize: 14,
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '24px',
                                letterSpacing: 0,
                                textTransform: "none",
                            }}
                        >Resend invite</Typography>
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
