import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Grid, IconButton, TextField, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openChangeBillingEmailDialog} from "../../store/reducers/dialogSlice";
import {changeSubscriptionOwner, getSubscriptionData, setBillingEmail} from "../../store/reducers/userSlice";

export default function ChangeBillingEmailDialog() {
    const open = useSelector((state) => state.dialogsOpen.changeBillingEmail.value);
    const billingEmail = useSelector((state) => state.user.billingEmail);
    const subscription = useSelector((state) => state.user.subscription);
    const originalBillingEmail = subscription.status > 1 ? subscription?.licensed_users?.find(user => user.user_id === subscription.subscription.owner_id).email || null : '';
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const handleClose = () => {
        store.dispatch(openChangeBillingEmailDialog(false));
        setError(false);
        setHelperText('');
        store.dispatch(setBillingEmail(originalBillingEmail));
    };
    const handleChangeBillingEmail = async () => {
        if (billingEmail.trim() === '') {
            setError(true);
            setHelperText('Enter email');
            return;
        }
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!billingEmail.toLowerCase().match(validRegex)) {
            setError(true);
            setHelperText('Enter correct email');
            return;
        }
        setError(false);
        setHelperText('');
        store.dispatch(openChangeBillingEmailDialog(false));
        await store.dispatch(changeSubscriptionOwner({'email': billingEmail}));
        await store.dispatch(getSubscriptionData());
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="change-billing-email-dialog-title"
            aria-describedby="change-billing-email-dialog-description"
            PaperProps={{
                sx: {
                    width: 444,
                    maxWidth: 444,
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Change billing email</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <TextField
                    error={error}
                    helperText={helperText}
                    autoFocus
                    margin="dense"
                    id="change_billing_email"
                    label="Billing email"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={billingEmail}
                    onChange={(event) => {
                        store.dispatch(setBillingEmail(event.target.value));
                    }}
                    InputLabelProps={{
                        style: {
                            color: error ? 'rgb(211, 47, 47)' : 'rgba(0, 0, 0, 0.60)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        },
                    }}
                    inputProps={{
                        sx: {
                            color: 'rgba(0, 0, 0, 0.54)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            fontFeatureSettings: "'clig' off, 'liga' off"
                        }
                    }}
                />
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleChangeBillingEmail}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Confirm</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
