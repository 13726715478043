import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosError} from "axios";

import axiosInstance from "../../api/axiosInstance";
import queue from "../../api/RequestQueue";
import {showNotification} from "./notificationSlice";

const initialState = {
    status: 'idle',
    error: null,
    groups: [],
    user: {},
    groupsInitialized: null,
    isCheckedLabels: false,
    isAllCheckedLabels: false,
    currentGroup: null,
    checkedLabels: [],
    groupsToShare: [],
    labelsFilter: {
        filter: null,
        sortBy: 'last_modified',
        order: 'DESC',
    },
    searchLabelsFilter: '',
    deleteCurrentGroup: null,
    labelToRename: {
        resource: null,
        name: null,
    },
    shareLabelStatus: 'idle',
    unShareLabelStatus: 'idle',
    createLabelStatus: 'idle',
    getLabelsStatus: 'idle',
    addExistingContactsToGroupStatus: 'idle',
    sharingUsers: [],
    sharingGroups: [],
    domainData: {},
    invitationPendingEmail: null,
}

export const getLabels = createAsyncThunk('contacts/getLabels', async (_, {rejectWithValue, requestId}) => {
        try {
            // console.log('getLabels requestId', requestId, Date.now())
            const data = {};
            const response = await axiosInstance(data, 'get_labels', 'get');
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    },
    {
        getPendingMeta: ({arg}) => {
            return {ignorePreviousRequest: true};
        }
    }
)

export const createGroup = createAsyncThunk('groups/createLabel', async (name, {rejectWithValue}) => {
    try {
        const data = {name};
        const response = await axiosInstance(data, 'create_label');
        response.data.message = 'Label created';
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const renameGroup = createAsyncThunk('groups/renameLabel', async (payload, {rejectWithValue}) => {
    try {
        const data = {resource: payload.resource, name: payload.name};
        const response = await axiosInstance(data, 'rename_group');
        response.data = {
            message: 'Label renamed',
        };
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const deleteGroups = createAsyncThunk('labels/deleteLabels', async (payload, {rejectWithValue}) => {
    try {
        const data = {
            resources: payload.map(item => item.group)
        };
        const response = await axiosInstance(data, 'remove_user_groups');
        response.data = {
            message: 'Label deleted',
        };
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})


export const shareGroups = createAsyncThunk('groups/shareGroups', async (payload, {rejectWithValue, dispatch}) => {
        try {
            const data = {...payload};
            const response = await axiosInstance(data, 'share_multi_list');
            const getRejectInfoArray = (data) => {
                const rejectMap = new Map();
                Object.values(data).forEach(group => {
                    group.rejected_emails.forEach(email => {
                        if (!rejectMap.has(email)) {
                            rejectMap.set(email, group.reject_reasons[email]);
                        }
                    });
                });
                return Array.from(rejectMap, ([email, reason]) => ({email, reason}));
            };
            const rejectInfoArray = getRejectInfoArray(response.data);
            if (rejectInfoArray.length) {
                const noSeatsError = rejectInfoArray.some(item => item.reason === "NO_SEATS");
                const restrictedByAdminError = rejectInfoArray.some(item => item.reason === "RESTRICTED_BY_ADMIN");
                let message = "You can't add license to some users.";
                if (noSeatsError) {
                    message = 'There are not enough unassigned licenses. Please purchase additional licenses.';
                }
                if (restrictedByAdminError) {
                    message = 'Some emails restricted by admin.';
                }
                dispatch(
                    showNotification({
                        type: 'error',
                        message,
                    })
                );
            }
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    },
    {
        getPendingMeta: ({arg}) => {
            return {ignorePreviousRequest: true};
        }
    }
)

export const changeUserRole = createAsyncThunk('groups/changeUserRole', async (payload, {rejectWithValue}) => {
    try {
        const data = {group: payload.group, change_user_id: payload.userId, email: payload.email, role: payload.role};
        const response = await axiosInstance(data, 'change_user_role');
        response.data.message = 'Permission changed';
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const addContactsToGroups = createAsyncThunk('groups/addContactsToGroups', async (payload, {rejectWithValue}) => {
    try {
        const data = {groups: payload.groupsResources, contacts: payload.contactsResources};
        const response = await axiosInstance(data, 'add_contacts_to_groups_list');
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const removeContactsFromGroup = createAsyncThunk('groups/removeContactsToGroup', async (payload, {rejectWithValue}) => {
    try {
        const data = {group: payload.groupResource, contacts: payload.contactsResources};
        const response = await axiosInstance(data, 'remove_contacts_from_group');
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const addExistingContactsToGroup = createAsyncThunk('groups/addContactsToGroup', async (payload, {rejectWithValue}) => {
    try {
        const data = {group: payload.groupResource, contacts: payload.contactsResources};
        const response = await axiosInstance(data, 'add_contacts_to_group');
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

export const unshareList = createAsyncThunk('groups/unshareList', async (payload, {rejectWithValue}) => {
    try {
        const data = {resources: payload.resources, emails: payload.emails};
        const response = await axiosInstance(data, 'unshare_list');
        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const errorResponse = error.response.data;
            return rejectWithValue(errorResponse);
        }
        throw error;
    }
})

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        updateGroups: (state, action) => {
            state.user = action.payload.user;
            state.groups = action.payload.groups;
            const defaultGroup = state.groups.find(item => item.resource === 'contactGroups/myContacts');
            if (!state.currentGroup) {
                state.currentGroup = defaultGroup;
            } else {
                const currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
                state.currentGroup = currentGroup || defaultGroup;
            }
            if (state.groupsToShare) {
                state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
            }
            state.groupsInitialized = true;
        },
        checkOne: (state, action) => {
            if (action.payload.status) {
                state.checkedLabels = [...state.checkedLabels, {group: action.payload.group}];
            } else {
                state.checkedLabels = state.checkedLabels.filter(item => item.group !== action.payload.group);
            }
            state.isCheckedLabels = state.checkedLabels.length > 0;
            state.isAllCheckedLabels = state.groups.length - 1 === state.checkedLabels.length;
        },
        checkAll: (state, action) => {
            if (state.isAllCheckedLabels === false && state.checkedLabels.length > 0) {
                state.checkedLabels = [];
            } else if (action.payload) {
                const searchLower = state.searchLabelsFilter.toLowerCase();
                const filteredGroups = state.groups.filter(item => item.name.toLowerCase().includes(searchLower));
                state.checkedLabels = filteredGroups.reduce((acc, item) => {
                    if (item.resource !== 'contactGroups/myContacts' && item.is_user_owner) {
                        return [...acc, {group: item.resource}];
                    }
                    return acc;
                }, []);
            } else {
                state.checkedLabels = [];
            }
            state.isCheckedLabels = state.checkedLabels.length > 0;
            state.isAllCheckedLabels = (state.groups.length - 1) === state.checkedLabels.length;
        },
        changeContacts: (state, action) => {
            state.currentGroup = state.groups.find(item => item.resource === action.payload.resource);
            // // If the groupsLookup doesn't exist, create it
            // if (!state.groupsLookup) {
            //     state.groupsLookup = Object.fromEntries(
            //         state.groups.map(group => [group.resource, group])
            //     );
            // }
            // // Use the lookup for O(1) access
            // state.currentGroup = state.groupsLookup[action.payload.resource] || null;
        },
        setGroupsToShare: (state, action) => {
            state.groupsToShare = state.groups.filter((group) => action.payload.groups.find((item) => group.resource === item.group));
        },
        setLabelsFilter: (state, action) => {
            state.labelsFilter = action.payload;
        },
        setSearchLabelsFilter: (state, action) => {
            state.searchLabelsFilter = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setDeleteCurrentGroup: (state, action) => {
            state.deleteCurrentGroup = action.payload;
        },
        setLabelToRename: (state, action) => {
            state.labelToRename = action.payload;
        },
        setUnShareLabelStatus: (state, action) => {
            state.unShareLabelStatus = action.payload;
        },
        updateSharingStatus: (state, action) => {
            const data = action.payload.data;
            state.groups = state.groups.map(group => {
                const updatedShare = group.share.map(sharedUser => {
                    if (sharedUser.user_id === data.finished_user_id) {
                        return { ...sharedUser, sync_status: data.status };
                    }
                    return sharedUser;
                });
                const isShareChanged = group.share.some(sharedUser => sharedUser.user_id === data.finished_user_id); // Check if the share array has changed
                if (isShareChanged) {
                    return { ...group, share: updatedShare };
                }
                return group;
            });
            state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
            state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
        },
        setDomainData: (state, action) => {
            state.domainData = action.payload;
        },
        setInvitationPendingEmail: (state, action) => {
            state.invitationPendingEmail = action.payload;
        },
        addNewContactToGroup: (state, action) => {
            const groupIndex1 = state.groups.findIndex(group => group.resource === action.payload.groupResource);
            if (groupIndex1 !== -1) {
                state.groups[groupIndex1] = {
                    ...state.groups[groupIndex1],
                    contacts_resources: [...state.groups[groupIndex1].contacts_resources, action.payload.contactResource]
                };
                state.groups[groupIndex1].count = state.groups[groupIndex1].contacts_resources.length;
            }
            state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
        },
        removeContactsFromGroups: (state, action) => {
            const contactsToRemove = new Set(action.payload.contacts); // Convert to Set for faster lookups
            state.groups = state.groups.map(group => {
                const contactsResources = group.contacts_resources.filter(contact => !contactsToRemove.has(contact));
                return {
                    ...group,
                    contacts_resources: contactsResources,
                    count: contactsResources.length
                };
            });
        },
        removeSharedUsersFromGroups: (state, action) => {
            const usersIds = action.payload.usersIds;
            state.groups = state.groups.map(group => {
                const sharedUsers = group.share.filter(sharedUser => !usersIds.find(item => item === sharedUser.user_id));
                return {
                    ...group,
                    share: sharedUsers,
                };
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getLabels.pending, (state) => {
                state.getLabelsStatus = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(getLabels.fulfilled, (state, action) => {
                state.getLabelsStatus = 'succeeded';
                state.needLogin = action.payload.need_login;
                if (!action.payload.need_login) {
                    state.sharingUsers = [];
                    state.sharingGroups = [];
                    state.groups = action.payload.groups.map(group => {
                        group.share = group.share.map(sharedUser => {
                            if (sharedUser.email === state.user?.email) {
                                sharedUser.sync_status = 'OK';
                            }
                            return sharedUser;
                        });
                        return group;
                    });
                    const defaultGroup = state.groups.filter(item => item.resource === 'contactGroups/myContacts')[0];
                    if (!state.currentGroup) {
                        state.currentGroup = defaultGroup;
                    } else {
                        const currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
                        state.currentGroup = currentGroup || defaultGroup;
                    }
                    if (state.groupsToShare) {
                        state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
                    }
                }
            })
            .addCase(getLabels.rejected, (state, action) => {
                state.getLabelsStatus = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
                state.createLabelStatus = 'failed';
            })
            .addCase(createGroup.pending, (state) => {
                state.status = 'loading';
                state.createLabelStatus = 'loading';
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.createLabelStatus = 'succeeded';
                const newGroup = {
                    contacts_resources: [],
                    count: 0,
                    is_user_owner: true,
                    name: action.payload.name,
                    owner: 0,
                    resource: action.payload.resourceName,
                    role: "SHARE",
                    share: [],
                    domains_groups_share: [],
                    update_time: new Date().toString(),
                };
                state.groups = [...state.groups, newGroup];
                state.currentGroup = newGroup;
                state.groupsToShare = [newGroup];
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(deleteGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = action.meta.arg.reduce((acc, item) => acc.filter(e => e.resource !== item.group), state.groups);
                state.isCheckedLabels = false;
                state.isAllCheckedLabels = false;
                state.checkedLabels = [];
                state.currentGroup = state.groups.filter(item => item.resource === 'contactGroups/myContacts')[0];
            })
            .addCase(deleteGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(shareGroups.pending, (state, action) => {
                state.sharingUsers = action.meta.arg.emails;
                state.sharingGroups = action.meta.arg.resources;
                state.shareLabelStatus = 'loading';
                state.groups = state.groups.map(group => {
                    if (action.meta.arg.resources.some(resource => resource === group.resource)) {
                        group.share.map(sharedUser => {
                            if (action.meta.arg.emails.some(email => email === sharedUser.email)) {
                                sharedUser.domain_group_email = null;
                                sharedUser.domain_group_id = null;
                                sharedUser.sync_status = "ON_QUEUE";
                                sharedUser.type = 1;
                            }
                            return sharedUser;
                        });
                        const uniqueEmails = !state.user?.domain_user ?
                            action.meta.arg.emails.filter(email => !group.share.some(shareUser => shareUser.email === email))
                            :
                            action.meta.arg.emails.filter(email => !group.share.some(shareUser => shareUser.email === email) && !state.domainData.groups.some(domainGroup => domainGroup.email === email));
                        const uniqueDomainGroups = [];
                        if (state.user?.domain_user) {
                            action.meta.arg.emails.forEach(email => {
                                const domainGroup = state.domainData.groups.find(domainGroup => domainGroup.email === email);
                                if (domainGroup) {
                                    const isShared = group.domains_groups_share.some(sharedDomainGroup => sharedDomainGroup.email === domainGroup.email);
                                    if (!isShared) {
                                        uniqueDomainGroups.push({
                                            ...domainGroup,
                                            id: null,
                                            role: action.meta.arg.role,
                                        });
                                    } else {
                                        group.share.forEach(sharedUser => {
                                            if (sharedUser.domain_group_email === domainGroup.email) {
                                                sharedUser.sync_status = 'ON_QUEUE';
                                            }
                                        });
                                    }
                                }
                            });
                        }
                        group.share = [...group.share, ...uniqueEmails.map(email => {
                            return {
                                domain_group_email: null,
                                domain_group_id: null,
                                email,
                                role: action.meta.arg.role,
                                sync_status: "ON_QUEUE",
                                type: 1,
                                user_id: null,
                            }
                        })];
                        if (state.user?.domain_user) {
                            group.domains_groups_share = [...group.domains_groups_share, ...uniqueDomainGroups];
                        }
                    }
                    return group;
                });
                state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
                state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
            })
            .addCase(shareGroups.fulfilled, (state, action) => {
                state.shareLabelStatus = 'succeeded';
                const getAllObjectsWithKeys = (data) => Object.entries(data).map(([key, value]) => ({key, ...value}));
                const allObjectsWithKeys = getAllObjectsWithKeys(action.payload);
                state.groups = state.groups.map(group => {
                    const matchingItem = allObjectsWithKeys.find(item => group.resource === item.key);
                    if (matchingItem) {
                        return {
                            ...group,
                            domains_groups_share: matchingItem.share_domain_groups,
                            share: matchingItem.share_users?.map(sharedUser => {
                                if (sharedUser.user_id === state.user.user_id) {
                                    sharedUser.sync_status = 'OK';
                                }
                                return sharedUser;
                            })
                        };
                    }
                    return group;
                });
                state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
                state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
            })
            .addCase(shareGroups.rejected, (state, action) => {
                state.error = action.error.message;
                state.shareLabelStatus = 'failed';
                state.sharingUsers = [];
                state.sharingGroups = [];
            })

            .addCase(changeUserRole.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(changeUserRole.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = state.groups.map(item => {
                    if (item.resource === action.payload.group) {
                        const shareUser = item.share.find(item => item.user_id === action.payload.change_user_id) || item.domains_groups_share.find(item => item.user_id === action.payload.change_user_id);
                        shareUser.role = action.payload.role;
                    }
                    return item;
                });
            })
            .addCase(changeUserRole.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(addContactsToGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addContactsToGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = state.groups.map(item => {
                    const group = action.payload.groups.find(group => item.resource === group);
                    if (group) {
                        const contactsResources = [...new Set([...item.contacts_resources, ...action.payload.contacts])];
                        return {...item, contacts_resources: contactsResources, count: contactsResources.length};
                    }
                    return item;
                });
            })
            .addCase(addContactsToGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(removeContactsFromGroup.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(removeContactsFromGroup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = state.groups.map(item => {
                    if (item.resource === action.payload.group) {
                        const contactsResources = item.contacts_resources.reduce((acc, item) => action.payload.contacts.includes(item) ? acc : [...acc, item], []);
                        return {...item, contacts_resources: contactsResources, count: contactsResources.length};
                    }
                    return item;
                });
                state.currentGroup = state.groups.find(group => group.resource === action.payload.group);
            })
            .addCase(removeContactsFromGroup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(addExistingContactsToGroup.pending, (state) => {
                state.addExistingContactsToGroupStatus = 'loading';
            })
            .addCase(addExistingContactsToGroup.fulfilled, (state, action) => {
                state.addExistingContactsToGroupStatus = 'succeeded';
                state.groups = state.groups.map(item => {
                    if (item.resource === action.payload.group) {
                        const contactsResources = [...new Set([...item.contacts_resources, ...action.payload.contacts])];
                        return {...item, contacts_resources: contactsResources, count: contactsResources.length};
                    }
                    return item;
                });
                state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
            })
            .addCase(addExistingContactsToGroup.rejected, (state, action) => {
                state.addExistingContactsToGroupStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(renameGroup.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(renameGroup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = state.groups.map(item => {
                    if (item.resource === action.meta.arg.resource) {
                        const date = new Date();
                        return {...item, name: action.meta.arg.name, update_time: date.toISOString()};
                    }
                    return item;
                });
                state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
            })
            .addCase(renameGroup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(unshareList.pending, (state, action) => {
                state.unShareLabelStatus = 'loading';
                state.groups = state.groups.map(group => {
                    if (group.resource === action.meta.arg.resources[0]) {
                        const email = action.meta.arg.emails[0];
                        group.share = group.share.filter(sharedUser => sharedUser.email !== email && sharedUser.domain_group_email !== email);
                        group.domains_groups_share = group.domains_groups_share.filter(sharedGroup => sharedGroup.email !== email);
                    }
                    return group;
                });
                state.groupsToShare = state.groups.filter((group) => state.groupsToShare.find((item) => group.resource === item.resource));
                state.currentGroup = state.groups.find(item => item.resource === state.currentGroup.resource);
            })
            .addCase(unshareList.fulfilled, (state, action) => {

            })
            .addCase(unshareList.rejected, (state, action) => {
                state.unShareLabelStatus = 'failed';
                state.error = action.error.message;
            })
    },
})

export const selectGroups = (state) => state.groups.groups;

export const {
    checkOne,
    checkAll,
    changeContacts,
    setGroupsToShare,
    setLabelsFilter,
    updateGroups,
    setSearchLabelsFilter,
    setStatus,
    setDeleteCurrentGroup,
    setLabelToRename,
    setUnShareLabelStatus,
    updateSharingStatus,
    setDomainData,
    setInvitationPendingEmail,
    addNewContactToGroup,
    removeContactsFromGroups,
    removeSharedUsersFromGroups,
} = groupsSlice.actions;

export default groupsSlice.reducer
