import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {
    AddCircleOutlineOutlined,
    CakeOutlined,
    CalendarTodayOutlined,
    CloseOutlined,
    EmailOutlined,
    FormatAlignLeftOutlined,
    HomeOutlined,
    PersonOutlineOutlined, PhoneOutlined,
    WorkOutlineOutlined,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import {openAddContactDialog} from "../../store/reducers/dialogSlice";
import {addNewContactToStore, createContact} from "../../store/reducers/contactsSlice";
import {addNewContactToGroup} from "../../store/reducers/groupsSlice";
import countries from '../../countries.json';
import store from "../../store/store";

const labelOptions = [
    {
        label: "Personal",
        value: "Personal",
    },
    {
        label: "Work",
        value: "Work",
    },
    {
        label: "Other",
        value: "Other",
    },
];

const defaultValues = {
    surname: '',
    name: '',
    organization: '',
    jobTitle: '',
    emails: [{email: '', label: ''}],
    phones: [{phone: '', label: ''}],
    country: '',
    region: '',
    city: '',
    street: '',
    street2: '',
    streetPostcode: '',
    dateOfBirth: null,
    description: '',
}

export default function AddNewContactDialog() {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const createContactsStatus = useSelector((state) => state.contacts.createContactsStatus);
    const loading = createContactsStatus === 'loading';
    const {handleSubmit, reset, control, formState: {errors}, setValue, watch} = useForm({
        defaultValues
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "emails"
    });
    const {fields: phonesFields, append: appendPhone, remove: removePhone} = useFieldArray({
        control,
        name: "phones"
    });
    const onSubmit = async data => {
        const date = new Date(data.dateOfBirth);
        data.birthday = data.dateOfBirth ? {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        } : null;
        data.groupResource = currentGroup?.resource;
        const createdContact = await dispatch(createContact(data));
        if (!createdContact.error) {
            await dispatch(addNewContactToStore({
                contact: {
                    id: createdContact.payload?.resourceName,
                    name: createdContact.payload?.name?.displayName,
                    memberships: [{
                        contactGroupMembership: {
                            contactGroupResourceName: currentGroup.resource,
                        }
                    }]
                }
            }));
            await store.dispatch(addNewContactToGroup({
                groupResource: currentGroup.resource,
                contactResource: createdContact.payload?.resourceName
            }));
            if (currentGroup.resource !== 'contactGroups/myContacts') {
                await store.dispatch(addNewContactToGroup({
                    groupResource: 'contactGroups/myContacts',
                    contactResource: createdContact.payload?.resourceName
                }));
            }
            handleClose();
        }
    }
    const open = useSelector((state) => state.dialogsOpen.addNewContact.value);
    const dispatch = useDispatch();
    const handleClose = () => {
        reset(defaultValues);
        dispatch(openAddContactDialog(false));
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                id='AddContactDialog'
                PaperProps={{
                    sx: {
                        width: 720,
                        maxWidth: 720,
                        minWidth: 720,
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                        <Grid container sx={{alignItems: 'center'}}>
                            <Grid item>
                                <Typography variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>
                                    Add new contact
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                            color: 'rgba(255, 255, 255, 0.54)',
                            '&:hover': {
                                color: 'rgba(255, 255, 255, 0.87)',
                                backgroundColor: 'rgba(0, 0, 0, 0.16)'
                            },
                        }}
                    >
                        <CloseOutlined/>
                    </IconButton>
                    <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                        <Box sx={{flexGrow: 1}}>
                            <Grid container
                                  alignItems="flex-end"
                            >
                                <Grid item xs={0.75}>
                                    <PersonOutlineOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"name"}
                                        label={"Name"}
                                        control={control}
                                        autoFocus
                                        rules={{
                                            required: true,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        sx={{marginLeft: 3}}
                                        name={"surname"}
                                        label={"Surname"}
                                        control={control}
                                        rules={{
                                            required: true,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05}/>

                                <Grid item xs={12} sx={{height: 48}}/>
                                <Grid item xs={0.75}>
                                    <WorkOutlineOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"organization"}
                                        label={"Name of the organization"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        sx={{marginLeft: 3}}
                                        name={"jobTitle"}
                                        label={"Job title"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05}/>

                                <Grid item xs={12} sx={{height: 48}}/>
                                {fields.map((field, index) => {
                                    return (
                                        <EmailFields
                                            key={field.id}
                                            index={index}
                                            control={control}
                                            append={append}
                                            remove={remove}
                                        />
                                    );
                                })}

                                <Grid item xs={12} sx={{height: 24}}/>
                                {phonesFields.map((field, index) => {
                                    return (
                                        <PhoneFields
                                            key={field.id}
                                            index={index}
                                            control={control}
                                            append={appendPhone}
                                            remove={removePhone}
                                        />
                                    );
                                })}
                                <Grid item xs={12}
                                      sx={{height: 48}}
                                />
                                <Grid item xs={0.75}>
                                    <HomeOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={5.1}>
                                    <CountryDropdown
                                        name={'country'}
                                        control={control}
                                        label={'Country'}
                                        options={countries}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <RegionDropdown
                                        name={'region'}
                                        control={control}
                                        label={'Region'}
                                        options={countries}
                                        sx={{marginLeft: 3}}
                                        watch={watch}
                                    />
                                </Grid>
                                <Grid item xs={1.05}/>

                                <Grid item xs={12} sx={{height: 24}}/>
                                <Grid item xs={0.75}/>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"city"}
                                        label={"City"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'street'}
                                        label={'Street address'}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                        sx={{marginLeft: 3}}
                                    />
                                </Grid>
                                <Grid item xs={1.05}/>

                                <Grid item xs={12} sx={{height: 24}}/>
                                <Grid item xs={0.75}/>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'street2'}
                                        label={'Street address line 2'}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'streetPostcode'}
                                        label={'Postal code'}
                                        control={control}
                                        sx={{marginLeft: 3}}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05}/>

                                <Grid item xs={12} sx={{height: 48}}/>
                                <Grid item xs={0.75}>
                                    <CakeOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={11.25}>
                                    <DateOfBirth control={control} setValue={setValue}/>
                                </Grid>

                                <Grid item xs={12} sx={{height: 24}}/>
                                <Grid item xs={0.75}>
                                    <FormatAlignLeftOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={11.25}>
                                    <FormInputText
                                        name={"description"}
                                        label={"Contact short description"}
                                        control={control}
                                        multiline
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                        <Button onClick={handleClose}><Typography variant={'dialogButton'}
                                                                  color={'rgba(0, 0, 0, 0.57)'}>Cancel</Typography></Button>
                        <LoadingButton type="submit" loading={loading}>
                            <Typography variant={'dialogButton'}
                                        sx={{
                                            color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                                        }}
                            >Create</Typography>
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

function EmailFields({index, control, append, remove}) {
    return (
        <>
            {index > 0 && (<Grid item xs={12} sx={{height: 24}}/>)}
            <Grid item xs={0.75}>
                <EmailOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
            </Grid>
            <Grid item xs={5.1}>
                <FormInputText
                    name={`emails[${index}].email`}
                    label={`Email`}
                    control={control}
                    type={'email'}
                    rules={{
                        required: false,
                        maxLength: {
                            value: 255,
                            message: "Max 255 symbols"
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputDropdown
                    name={`emails[${index}].label`}
                    control={control}
                    label={'Label'}
                    options={labelOptions}
                    sx={{marginLeft: 3}}
                />
            </Grid>
            {
                index ?
                    <Grid item xs={1.05}
                          sx={{
                              display: 'flex',
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                    >
                        <IconButton edge='end' aria-label="close"
                                    onClick={() => {
                                        remove(index)
                                    }}>
                            <CloseOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    :
                    <Grid item xs={1.05}
                          sx={{
                              display: 'flex',
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                    >
                        <IconButton
                            edge='end'
                            onClick={() => {
                                append({email: '', label: ''});
                            }}
                        >
                            <AddCircleOutlineOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
            }
        </>
    )
}


function PhoneFields({index, control, append, remove}) {
    return (
        <>
            {index > 0 && (<Grid item xs={12} sx={{height: 24}}/>)}
            <Grid item xs={0.75}>
                <PhoneOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
            </Grid>
            <Grid item xs={5.1}>
                <FormInputText
                    name={`phones.${index}.phone`}
                    label={`Phone`}
                    control={control}
                    rules={{
                        required: false,
                        maxLength: {
                            value: 255,
                            message: "Max 255 symbols"
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputDropdown
                    name={`phones.${index}.label`}
                    control={control}
                    label={'Label'}
                    options={labelOptions}
                    sx={{marginLeft: 3}}
                />
            </Grid>
            {
                index ?
                    <Grid item xs={1.05}
                          sx={{
                              display: 'flex',
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                    >
                        <IconButton edge='end' aria-label="close"
                                    onClick={() => {
                                        remove(index)
                                    }}>
                            <CloseOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    :
                    <Grid item xs={1.05}
                          sx={{
                              display: 'flex',
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                          }}
                    >
                        <IconButton
                            edge='end'
                            onClick={() => {
                                append({phone: '', label: ''});
                            }}
                        >
                            <AddCircleOutlineOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
            }
        </>
    )
}


function DateOfBirth({control, setValue}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                defaultValue={null}
                name={'dateOfBirth'}
                control={control}
                render={({field: {onChange, value}}) => (
                    <DatePicker
                        // format='MM/DD/YY'
                        label="Date of birth"
                        value={value}
                        onChange={(date) => {
                            setValue('dateOfBirth', date, {shouldValidate: true, shouldDirty: true});
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayOutlined
                        }}
                        slotProps={{
                            textField: {
                                InputProps: {
                                    size: 'medium',
                                    readOnly: true,
                                    sx: {
                                        width: '285.59px',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                                InputLabelProps: {
                                    sx: {
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                                variant: 'standard'
                            }
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

const FormInputText = ({name, control, label, rules, sx, autoFocus = false, type = 'text', multiline = false}) => {
    return (
        <Controller
            defaultValue={''}
            name={name}
            control={control}
            rules={rules}
            render={({
                         field: {onChange, value},
                         fieldState: {error},
                         formState,
                     }) => {
                return (
                    <TextField
                        type={type}
                        helperText={error ? error.message : null}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        autoFocus={autoFocus}
                        label={label}
                        fullWidth
                        variant="standard"
                        sx={sx}
                        multiline={multiline}
                        InputLabelProps={{
                            style: {
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        }}
                        inputProps={{
                            'data-lpignore': 'true',
                            autoComplete: 'off',
                            sx: {
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            }
                        }}
                    />
                )
            }
            }
        />
    );
};

const FormInputDropdown = ({name, control, label, options, sx = null}) => {
    const generateSingleOptions = () => {
        return options.map((option) => {
            return (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({field: {onChange, value}}) => (
                    <Select
                        sx={{
                            color: 'rgba(0, 0, 0, 0.84)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                            },
                        }}
                        onChange={onChange}
                        value={value}
                        label={label}
                        defaultValue={''}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};

const CountryDropdown = ({name, control, label, options, sx = null}) => {
    const generateSingleOptions = () => options.map((option) => (
        <MenuItem key={option.country.name} value={option.country.shortCode}>
            {option.country.name}
        </MenuItem>
    ));
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({field: {onChange, value}}) => (
                    <Select
                        sx={{
                            color: 'rgba(0, 0, 0, 0.84)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                            },
                        }}
                        onChange={onChange}
                        value={value}
                        label={label}
                        defaultValue={''}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};

const RegionDropdown = ({name, control, label, options, sx = null, watch}) => {
    const country = options.find(country => country.country.shortCode === watch('country'));
    const generateSingleOptions = () => country && country.country.regions ? country.country.regions.map((region) => (
        <MenuItem key={region.name} value={region.name}>
            {region.name}
        </MenuItem>
    )) : [];
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({field: {onChange, value}}) => (
                    <Select
                        sx={{
                            color: 'rgba(0, 0, 0, 0.84)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                            },
                        }}
                        onChange={onChange}
                        value={value}
                        label={label}
                        defaultValue={''}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};
