import * as React from "react";
import {LabelOffOutlined, AddOutlined} from "@mui/icons-material";
import {Button, Typography, Stack} from "@mui/material";
import store from "../../store/store";
import {openAddNewLabelDialog} from "../../store/reducers/dialogSlice";

export default function NoLabelsFound() {
    const handleClickOpenAddNewLabelDialog = () => {
        store.dispatch(openAddNewLabelDialog(true));
    };
    return (
        <Stack
            spacing={2}
            sx={{
                marginTop: 5,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <LabelOffOutlined sx={{width: 48, height: 48, color: 'rgba(0, 0, 0, 0.54)'}}/>
            <Typography variant={'subtitle2'}>No labels found</Typography>
            <Button
                variant={'outlined'}
                color="inherit"
                size="small"
                startIcon={<AddOutlined sx={{color: 'rgba(32, 33, 36, 1)'}}/>}
                sx={{
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    width: 140,
                    height: 36,
                }}
                onClick={handleClickOpenAddNewLabelDialog}
            >
                <Typography variant={'button'} sx={{color: 'rgba(32, 33, 36, 1)', textTransform: "none", letterSpacing: 0,}}>Create label</Typography>
            </Button>
        </Stack>
    )
}
