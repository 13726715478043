import Stack from "@mui/material/Stack";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function NoRowsOverlay() {
    <Stack height="100%" alignItems="center" justifyContent="center">
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                width: '243px',
            }}
        >
            <Typography
                sx={{
                    color: 'rgba(60, 64, 67, 1)',
                    fontFamily: 'Open Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                    letterSpacing: '-0.72px',
                }}
            >
                There are no contacts
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '-0.56px',
                    textAlign: 'center',
                }}
            >
                Contacts with whom you share this label will be displayed here
            </Typography>
        </Box>
    </Stack>
}
