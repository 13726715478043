import {useSelector} from "react-redux";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {CloseOutlined} from "@mui/icons-material";
import Stack from "@mui/material/Stack";

// project import
import store from "../../store/store";
import {openReviewAndCheckoutDialog} from "../../store/reducers/dialogSlice";

export default function ReviewAndCheckoutDialog({counter, handleConfirm}) {
    const open = useSelector((state) => state.dialogsOpen.reviewAndCheckout.value);
    const subscription = useSelector((state) => state.user.subscription);
    const amountChanges = useSelector((state) => state.user.amountChanges);
    const currencySign = subscription.subscription.currency === 'EUR' ? '€' : '$';
    const countAddingLicenses = counter - subscription.quantity;
    const handleClose = () => {
        store.dispatch(openReviewAndCheckoutDialog(false));
    };
    const getNextPaymentDate = (nextPaymentDate) => {
        const date = new Date(Date.parse(nextPaymentDate));
        return `${date.toLocaleString('default', {month: 'long'})} ${date.getUTCDate()}`
    }
    const nextPaymentDate = getNextPaymentDate(amountChanges.next_payment_date);
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                id='ShareLabelDialog'
                PaperProps={{
                    sx: {
                        width: 720,
                        maxWidth: 720,
                        minWidth: 444,
                        height: 252,
                        backgroundColor: 'rgba(59, 125, 237, 1)',
                    }
                }}
            >
                <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                    <Grid container sx={{alignItems: 'center'}}>
                        <Grid item>
                            <Typography
                                variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Review and checkout</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'rgba(255, 255, 255, 0.54)',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: 'rgba(0, 0, 0, 0.16)'
                        },
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
                <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Stack spacing={1} sx={{marginTop: '15px'}}>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontFamily: `"Open Sans"`,
                                fontSize: 16,
                                fontWeight: 'SemiBold',
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                            }}
                        >
                            This will change the number of licences to {counter}.
                        </Typography>
                        {counter > subscription.quantity &&
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontFamily: "Open Sans",
                                fontSize: 16,
                                fontWeight: 'Regular',
                                fontStyle: 'normal',
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }}
                        >
                            You'll be charged a one-time pro-rated payment
                            of <Box component="span" fontWeight='Bold' color={'rgba(0, 0, 0, 0.87)'}>{currencySign}{amountChanges.pay_now}</Box> in order to add {countAddingLicenses} licence{countAddingLicenses > 1 ? 's' : ''} to
                            your subscription that covers the time between now and your next billing cycle
                            on <Box component="span" fontWeight='Bold' color={'rgba(0, 0, 0, 0.87)'}>{nextPaymentDate}</Box>.</Typography>}
                        {counter < subscription.quantity &&
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontFamily: `"Open Sans"`,
                                fontSize: 14,
                                fontWeight: 'Regular',
                                fontStyle: 'normal',
                                lineHeight: '20px',
                                letterSpacing: '-.02em',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }}
                        >To cover the previously made payment, you will be
                            billed <Box component="span" fontWeight='Bold' color={'rgba(0, 0, 0, 0.87)'}>{currencySign}{amountChanges.next_payment}</Box> instead
                            of {currencySign}{amountChanges.amount} on the
                            following billing cycle on <Box component="span" fontWeight='Bold' color={'rgba(0, 0, 0, 0.87)'}>{nextPaymentDate}</Box> and so on until your credit runs out.</Typography>}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Button onClick={handleClose}>
                        <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                    </Button>
                    <Button onClick={handleConfirm}>
                        <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Confirm</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
