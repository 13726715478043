import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid, IconButton,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {CloseOutlined} from "@mui/icons-material";
import * as React from "react";

// project import
import store from "../../store/store";
import {openWarningShareAllContactsDialog, openShareLabelsDialog} from "../../store/reducers/dialogSlice";
import {setGroupsToShare} from "../../store/reducers/groupsSlice";

export default function WarningShareAllContactsDialog() {
    const open = useSelector((state) => state.dialogsOpen.warningShareAllContacts.value);
    const handleClose = () => {
        store.dispatch(setGroupsToShare({groups: []}));
        store.dispatch(openWarningShareAllContactsDialog(false));
    };
    const handleContinue = async () => {
        store.dispatch(openWarningShareAllContactsDialog(false));
        store.dispatch(openShareLabelsDialog(true));
    };
    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='WarningShareAllContactsDialog'
                PaperProps={{
                    sx: {
                        width: 444,
                        maxWidth: 444,
                        minWidth: 444,
                        backgroundColor: 'rgba(59, 125, 237, 1)',
                    }
                }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.02em',
                    }}>
                    Are you sure you want to share all your Google Contacts?
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleContinue}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Continue</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
