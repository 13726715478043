import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid, IconButton,
    TextField,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {CloseOutlined} from "@mui/icons-material";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import {createGroup} from "../../store/reducers/groupsSlice";
import {openAddNewLabelDialog, openShareLabelsDialog} from "../../store/reducers/dialogSlice";

export default function CreateLabelDialog() {
    const open = useSelector((state) => state.dialogsOpen.addNewLabel.value);
    const groups = useSelector((state) => state.groups.groups);
    const createLabelStatus = useSelector((state) => state.groups.createLabelStatus);
    const loading = createLabelStatus === 'loading';
    const handleClose = () => {
        setError(false);
        setHelperText('');
        store.dispatch(openAddNewLabelDialog(false));
    };
    const handleCreateGroup = async () => {
        const labelName = document.getElementById('label_name').value;
        if (labelName.trim() === '') {
            setError(true)
            setHelperText('Enter name')
            return;
        }
        if (labelName.trim().length > 255) {
            setError(true);
            setHelperText('Max 255 symbols');
            return;
        }
        if (groups.find(item => item.name === labelName)) {
            setError(true)
            setHelperText('Label name already exist')
            return;
        }
        await store.dispatch(createGroup(labelName));
        store.dispatch(openAddNewLabelDialog(false));
        store.dispatch(openShareLabelsDialog(true));
    };
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='CreateLabelDialog'
                PaperProps={{
                    sx: {
                        width: 444,
                        maxWidth: 444,
                        minWidth: 444,
                        // height: 230,
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Create a new label</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Box sx={{marginTop: '15px'}}>
                    <TextField
                        error={error}
                        helperText={helperText}
                        autoFocus
                        margin="dense"
                        id="label_name"
                        label="Label Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{
                            style: {
                                color: error ? 'rgb(211, 47, 47)' : 'rgba(0, 0, 0, 0.60)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '12px',
                                letterSpacing: '0.15px',
                            },
                        }}
                        inputProps={{
                            sx: {
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <LoadingButton loading={loading} onClick={handleCreateGroup}>
                    <Typography variant={'dialogButton'}
                                sx={{
                                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                                }}
                    >Create</Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
