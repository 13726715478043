import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IconButton, TextField} from '@mui/material';
import {styled} from "@mui/material/styles";
import {AddOutlined, RemoveOutlined} from "@mui/icons-material";
import Stack from "@mui/material/Stack";

// project import
import {
    getAmount,
    setPricePerUser,
    setCounter,
} from "../../store/reducers/userSlice";
import {usersCountToPlanCount} from "../../services/usersCountToPlanCount";

export default function QuantityLicenses({error, setError}) {
    const dispatch = useDispatch();
    const subscription = useSelector((state) => state.user.subscription);
    const connectedUsersCount = subscription.connected_users.length;
    const SUBSCRIPTION_OVER = subscription.plan_id !== 1 && subscription.status === 0;
    const TRIAL = subscription.plan_id === 1;
    const subscriptionVersion = SUBSCRIPTION_OVER || TRIAL ? 3 : subscription.subscription.version;
    const currency = useSelector((state) => state.user.currency);
    const counter = useSelector((state) => state.user.counter);
    const subscriptionType = useSelector((state) => state.user.subscriptionType);
    const handleIncrement = async () => {
        if (subscriptionVersion === 2) {
            if (counter === 5000) return counter;
            const planCount = usersCountToPlanCount(counter + 1);
            dispatch(setCounter(planCount.to));
            if (counter >= connectedUsersCount) {
                setError(false)
            }
            const data = {
                period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
                quantity: planCount.to,
                version: subscriptionVersion,
                currency,
            }
            const amount = await dispatch(getAmount(data));
            dispatch(setPricePerUser(Math.trunc((amount.payload.price / amount.payload.full_quantity) * 100) / 100));
            return counter;
        }
        if (counter >= connectedUsersCount) {
            setError(false)
        }
        const newCounter = counter + 1;
        dispatch(setCounter(newCounter));
        const data = {
            period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
            quantity: newCounter,
            version: subscriptionVersion,
            currency,
        }
        const amount = await dispatch(getAmount(data));
        dispatch(setPricePerUser(Math.trunc((amount.payload.price / newCounter) * 100) / 100));
        return counter;
    };
    const handleDecrement = async () => {
        if (subscriptionVersion === 2) {
            const planCount = usersCountToPlanCount(counter);
            let newCounter = planCount.from - 1;
            if (newCounter < 1) {
                newCounter = counter;
            }
            if (newCounter > 0 && newCounter < connectedUsersCount) {
                setError(true);
                newCounter = counter;
            }
            dispatch(setCounter(newCounter));
            const data = {
                period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
                quantity: newCounter,
                version: subscriptionVersion,
                currency,
            }
            const amount = await dispatch(getAmount(data));
            dispatch(setPricePerUser(Math.trunc((amount.payload.price / amount.payload.full_quantity) * 100) / 100));
            return counter;
        }
        let newCounter = counter - 1;
        if (newCounter < 2) {
            newCounter = counter;
        }
        if (newCounter < connectedUsersCount) {
            if (subscriptionVersion === 2) {
                setError(true);
            }
            newCounter = counter;
        }
        dispatch(setCounter(newCounter));
        const data = {
            period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
            quantity: newCounter,
            version: subscriptionVersion,
            currency,
        }
        const amount = await dispatch(getAmount(data));
        dispatch(setPricePerUser(Math.trunc((amount.payload.price / newCounter) * 100) / 100));
        return counter;
    };
    const handleInputNumber = (value) => {
        const number = Number(value);
        if (Number.isNaN(number)) return;
        if (subscriptionVersion === 2) {
            const prepareValue = () => {
                const normalizedValue = Math.abs(value) > 5000 ? 5000 : Math.abs(value);
                return normalizedValue === 0 || normalizedValue < connectedUsersCount ? connectedUsersCount : normalizedValue;
            }
            const planCount = usersCountToPlanCount(prepareValue());
            if (number < connectedUsersCount) {
                setError(true);
            } else {
                setError(false);
            }
            dispatch(setCounter(planCount.to));
            const data = {
                period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
                quantity: prepareValue(),
                version: subscriptionVersion,
                currency,
            }
            dispatch(getAmount(data));
            return;
        }
        let preparedValue = Math.abs(value) === 0 || Math.abs(value) < connectedUsersCount ? connectedUsersCount : Math.abs(value);
        preparedValue = preparedValue < 2 ? 2 : preparedValue;
        if (number < connectedUsersCount) {
            setError(true);
        } else {
            setError(false);
        }
        dispatch(setCounter(preparedValue));
        const data = {
            period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
            quantity: preparedValue,
            version: subscriptionVersion,
            currency,
        }
        dispatch(getAmount(data));
    };
    return (
        <Stack direction={"row"} spacing={1}>
            <IconButton
                onClick={handleDecrement}
                sx={{
                    width: 42,
                    height: 42,
                    borderRadius: '4px',
                    background: '#F1F3F4',
                }}
            >
                <RemoveOutlined/>
            </IconButton>
            <StyledTextField
                sx={{
                    width: 120,
                    height: 42,
                    display: "flex",
                    justifyContent: "center",
                }}
                error={error}
                value={counter || ''}
                onChange={(event) => {
                    handleInputNumber(event.target.value);
                }}
                InputProps={{
                    style: {
                        height: 42,
                        width: '100%',
                        color: error ? 'rgba(84, 19, 19, 1)' : 'rgba(32, 33, 36, 1)',
                        fontSize: '14px',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        backgroundColor: error && '#FBEAEA'
                    }
                }}
            />
            <IconButton
                onClick={handleIncrement}
                sx={{
                    width: 42,
                    height: 42,
                    borderRadius: '4px',
                    background: '#F1F3F4',
                }}
            >
                <AddOutlined/>
            </IconButton>
        </Stack>
    );
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
        },
    },
}));
