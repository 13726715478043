import Typography from "@mui/material/Typography";

export default function LicenseMessage({subscription, subscriptionInitialized, noLicensedEmailValues, availableSeats}) {
    if (!subscriptionInitialized) return null;
    let msg = '';
    if (subscription.plan_id === 1) {
        if (subscription.status === 0) {
            msg = `Trial expired`
        } else {
            return null
        }
    } else if (subscription.status === 0) {
        msg = 'Your subscription has expired. Please subscribe';
    } else if (subscription.status === 2) {
        msg = 'You can add only users with your domain or with licences'
    } else if (noLicensedEmailValues.length > availableSeats) {
        msg = `You are out of licenses`
    } else {
        return null
    }
    return <Typography color='error'>{msg}</Typography>
}
