import * as React from "react";
import {useSelector} from "react-redux";
import {
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    LinearProgress,
    Stack,
    SvgIcon,
    Toolbar,
    Typography,
    Link,
} from "@mui/material";
import {Info, PeopleAltOutlined, TaskOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

// project import

const drawerWidth = 260;

export default function SidebarManageUsers() {
    const navigate = useNavigate();
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const user = useSelector((state) => state.user);
    const domainData = useSelector((state) => state.user.domainData);
    const subscription = useSelector((state) => state.user.subscription);
    const domainUsersCount = useSelector((state) => state.user.shareableUsers.domainUsersCount);
    let usersWithLicense = [];
    let deletedUsers = [];
    let bannedUsers = [];
    usersWithLicense = subscription.active_quantity;
    bannedUsers = subscription.restricted_users || [];
    deletedUsers = subscription.deleted_users?.filter(deletedUser =>
        !subscription.restricted_users.some(restrictedUser => restrictedUser.email === deletedUser.email)
    ) || [];
    let usersWithoutLicense = deletedUsers.length + bannedUsers.length;
    if (user.user.domain_user) {
        const unlicensedDomainUsers = user.domainData?.users?.filter(domainUser =>
            !subscription.licensed_users.some(licensedUser => licensedUser.email === domainUser.email)
        ) || [];
        const isNotUnique = (user, users) => users.some(existingUser => existingUser.email === user.email);
        const uniqueUnlicensedDomainUsers = unlicensedDomainUsers.filter(
            user => !isNotUnique(user, deletedUsers) && !isNotUnique(user, bannedUsers)
        );
        usersWithoutLicense += uniqueUnlicensedDomainUsers.length;
    }
    const assignedLicenses = subscription.active_quantity * 100 / subscription.quantity;
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', border: 0},
            }}
        >
            <Toolbar/>
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Toolbar sx={{height: 72}}>
                <Typography sx={{
                    color: 'rgba(60, 64, 67, 1)',
                    fontSize: 28,
                    fontFamily: "Open Sans",
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.56px',
                }}>
                    Manage Users
                </Typography>
            </Toolbar>
            <Toolbar>
                <Typography variant={'body2'} sx={{letterSpacing: '0.17px', color: 'rgba(0, 0, 0, 0.6)'}}>
                    Users breakdown
                </Typography>
            </Toolbar>
            <Stack spacing={2}>
                <Box sx={{
                    overflow: 'auto',
                }}
                     display="flex"
                     alignItems="center"
                     justifyContent="right"
                >
                    <Stack spacing={'12px'}>
                        {/* Disabled Task #704 */}
                        {false && user.user.domain_user ? <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                width: 234,
                                height: 74,
                                flexShrink: 0,
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Grid container spacing={0}
                                  sx={{padding: '0 12px 0 12px'}}
                            >
                                <Grid item xs={2}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="left"
                                >
                                    <PeopleAltOutlined sx={{width: 22, height: 22, color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={9} display="flex" alignItems="center">
                                    <Typography
                                        variant={'body2'}
                                        sx={{color: 'rgba(32, 33, 36, 1)', letterSpacing: '0.17px'}}
                                    >
                                        Total users in domain
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} display="flex" alignItems="center" justifyContent="right">
                                    {/* <Info sx={{width: 16, height: 16, color: 'rgba(0, 0, 0, 0.32)'}}/> */}
                                </Grid>
                                <Grid item xs={12}/>
                                <Grid item xs={2}/>
                                <Grid item xs={10} display="flex" alignItems="center"><Typography sx={{
                                    color: 'rgba(60, 64, 67, 1)',
                                    fontSize: 18,
                                    fontFamily: "Open Sans",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '-0.72px',
                                }}>{domainUsersCount || 0}</Typography></Grid>
                            </Grid>
                        </Box> : null}
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                width: 234,
                                height: 74,
                                flexShrink: 0,
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Grid container spacing={0}
                                  sx={{padding: '0 12px 0 12px'}}
                            >
                                <Grid item xs={2}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="left"
                                >
                                    <TaskOutlined sx={{width: 22, height: 22, color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </Grid>
                                <Grid item xs={9} display="flex" alignItems="center">
                                    <Typography variant={'body2'}
                                                sx={{color: 'rgba(32, 33, 36, 1)', letterSpacing: '0.17px'}}>
                                        Licenses</Typography>
                                </Grid>
                                <Grid item xs={1} display="flex" alignItems="center" justifyContent="right">
                                    {/* <Info sx={{width: 16, height: 16, color: 'rgba(0, 0, 0, 0.32)'}}/> */}
                                </Grid>
                                <Grid item xs={12}/>
                                <Grid item xs={2}/>
                                <Grid item xs={10} display="flex" alignItems="center"><Typography sx={{
                                    color: 'rgba(60, 64, 67, 1)',
                                    fontSize: 18,
                                    fontFamily: "Open Sans",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '-0.72px',
                                }}>{subscription.quantity || 0}</Typography></Grid>
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                width: 234,
                                height: 74,
                                flexShrink: 0,
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Grid container spacing={0}
                                  sx={{padding: '0 12px 0 12px'}}
                            >
                                <Grid item xs={2}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="left"
                                >
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22"
                                             viewBox="0 0 24 22" fill="none">
                                            <path
                                                d="M13 7C13 4.79 11.21 3 9 3C6.79 3 5 4.79 5 7C5 9.21 6.79 11 9 11C11.21 11 13 9.21 13 7ZM11 7C11 8.1 10.1 9 9 9C7.9 9 7 8.1 7 7C7 5.9 7.9 5 9 5C10.1 5 11 5.9 11 7Z"
                                                fill="black" fillOpacity="0.54"/>
                                            <path
                                                d="M1 17V19H17V17C17 14.34 11.67 13 9 13C6.33 13 1 14.34 1 17ZM3 17C3.2 16.29 6.3 15 9 15C11.69 15 14.77 16.28 15 17H3Z"
                                                fill="black" fillOpacity="0.54"/>
                                            <path d="M23 7L18 12L15 9" stroke="black" strokeOpacity="0.54"
                                                  strokeWidth="2"/>
                                        </svg>
                                    </SvgIcon>
                                </Grid>
                                <Grid item xs={9} display="flex" alignItems="center">
                                    <Typography variant={'body2'}
                                                sx={{color: 'rgba(32, 33, 36, 1)', letterSpacing: '0.17px'}}>
                                        Users with licenses</Typography>
                                </Grid>
                                <Grid item xs={1} display="flex" alignItems="center" justifyContent="right">
                                    {/* <Info sx={{width: 16, height: 16, color: 'rgba(0, 0, 0, 0.32)'}}/> */}
                                </Grid>
                                <Grid item xs={12}/>
                                <Grid item xs={2}/>
                                <Grid item xs={10} display="flex" alignItems="center"><Typography sx={{
                                    color: 'rgba(60, 64, 67, 1)',
                                    fontSize: 18,
                                    fontFamily: "Open Sans",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '-0.72px',
                                }}>{usersWithLicense}</Typography></Grid>
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                width: 234,
                                height: 74,
                                flexShrink: 0,
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <Grid container spacing={0}
                                  sx={{padding: '0 12px 0 12px'}}
                            >
                                <Grid item xs={2}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="left"
                                >
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22"
                                             viewBox="0 0 23 22" fill="none">
                                            <path
                                                d="M13 7C13 4.79 11.21 3 9 3C6.79 3 5 4.79 5 7C5 9.21 6.79 11 9 11C11.21 11 13 9.21 13 7ZM11 7C11 8.1 10.1 9 9 9C7.9 9 7 8.1 7 7C7 5.9 7.9 5 9 5C10.1 5 11 5.9 11 7Z"
                                                fill="black" fillOpacity="0.54"/>
                                            <path
                                                d="M1 17V19H17V17C17 14.34 11.67 13 9 13C6.33 13 1 14.34 1 17ZM3 17C3.2 16.29 6.3 15 9 15C11.69 15 14.77 16.28 15 17H3Z"
                                                fill="black" fillOpacity="0.54"/>
                                            <path d="M22 7L19 10M16 13L19 10M19 10L16 7L22 13" stroke="black"
                                                  strokeOpacity="0.54" strokeWidth="2"/>
                                        </svg>
                                    </SvgIcon>
                                </Grid>
                                <Grid item xs={9} display="flex" alignItems="center">
                                    <Typography variant={'body2'}
                                                sx={{color: 'rgba(32, 33, 36, 1)', letterSpacing: '0.17px'}}>
                                        Users without licenses
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} display="flex" alignItems="center" justifyContent="right">
                                    {/* <Info sx={{width: 16, height: 16, color: 'rgba(0, 0, 0, 0.32)'}}/> */}
                                </Grid>
                                <Grid item xs={12}/>
                                <Grid item xs={2}/>
                                <Grid item xs={10} display="flex" alignItems="center"><Typography sx={{
                                    color: 'rgba(60, 64, 67, 1)',
                                    fontSize: 18,
                                    fontFamily: "Open Sans",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '-0.72px',
                                }}>{usersWithoutLicense}</Typography></Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </Box>
                <Divider/>
                <Box sx={{
                    overflow: 'auto',
                }}
                     display="flex"
                     alignItems="center"
                     justifyContent="right"
                >
                    <Stack sx={{width: 234}} spacing={2}>
                        <Stack spacing={1}>
                            <LinearProgress variant="determinate" value={assignedLicenses || 0}/>
                            <Typography sx={{
                                color: 'rgba(32, 33, 36, 0.54)',
                                fontSize: 12,
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                            }}>Assigned {subscription.active_quantity} out
                                of {subscription.quantity} licences</Typography>
                        </Stack>
                        <Button
                            variant='outlined'
                            size="medium"
                            sx={{
                                width: 182,
                                borderColor: 'rgba(0, 0, 0, 0.54)'
                            }}
                            onClick={() => navigate("/manage_subscription")}
                        >
                            <Typography variant={'button'}
                                        sx={{textTransform: "none", color: 'rgba(25, 103, 210, 1)'}}>
                                Manage subscription
                            </Typography>
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Drawer>
    )

}
