const currentRequestIds = {}; // Track the current request's ID

// Middleware to ignore previous requests
const ignorePreviousRequestMiddleware = (store) => (next) => (action) => {
    // Handle pending action (when a new request starts)
    if (action.type.endsWith("/pending")) {
        if (action.meta?.ignorePreviousRequest) {
            const modifiedActionType = action.type.replace(/\/pending$/, '');
            currentRequestIds[modifiedActionType] = action.meta.requestId; // Update to the new request's ID
        }
    }

    // Handle fulfilled action (when a request finishes successfully)
    if (action.type.endsWith("/fulfilled")) {
        const modifiedActionType = action.type.replace(/\/fulfilled$/, '');
        // Ignore the response from a previous request if IDs don't match
        if (currentRequestIds[modifiedActionType] !== undefined && action.meta.requestId !== currentRequestIds[modifiedActionType]) {
            // console.log('Declined requestId', action.type, action.meta.requestId)
            return null;
        }
        // if (currentRequestIds[modifiedActionType] === undefined) {
        //     console.log('Passed requestId', action.type, action.meta.requestId)
        // } else {
        //     console.log('Approved requestId', action.type, action.meta.requestId)
        // }
    }

    // Handle rejected action (when a request fails)
    if (action.type.endsWith("/rejected")) {
        const modifiedActionType = action.type.replace(/\/rejected$/, '');
        // Ignore the response from a previous request if IDs don't match
        if (action.meta.requestId !== currentRequestIds[modifiedActionType]) {
            return null;
        }
    }
    return next(action); // Let the action continue through reducers/middleware
};

export default ignorePreviousRequestMiddleware;

