import * as React from 'react';
import {Stack} from "@mui/material";
import {useSelector} from "react-redux";
import {StyledDataGrid} from "../../StyledDataGrid";
import INVOICES from "../../../_mock/invoices";

export default function InvoicesTable() {
    const topHeight = 64 + 48 + 24 + 24 + 1;
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'date',
            sort: 'asc'
        }
    ]);
    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        },
        {
            field: 'invoice',
            headerName: 'Invoice',
            flex: 1,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            flex: 1,
        },
        {
            field: 'receipt',
            headerName: 'Receipt, PDF',
            flex: 1,
            renderCell: params => (
                <>
                    <a href={''}>{params.value}</a>
                </>
            )
        },
    ];
    const rows = INVOICES;
    return (
        <Stack sx={{width: '100%'}}>
            <div style={{
                height: domainInstallAlert ? `calc(100vh - ${topHeight}px - 48px)` : `calc(100vh - ${topHeight}px)`,
                width: '100%',
            }}>
                <StyledDataGrid
                    disableRowSelectionOnClick
                    rows={rows}
                    columns={columns}
                    columnHeaderHeight={56}
                    rowHeight={72}
                    autoPageSize
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </div>
        </Stack>
    );
}
