import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from "react-redux";
import {Grid, IconButton, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openAlertDeleteLicenseDialog} from "../../store/reducers/dialogSlice";
import {
    getSubscriptionData,
    removeUsersFromSubscription,
    setLicensesToDelete,
    setSelectedLicenses
} from "../../store/reducers/userSlice";
import {showNotification} from "../../store/reducers/notificationSlice";
import {removeSharedUsersFromGroups} from "../../store/reducers/groupsSlice";

export default function AlertDeleteLicenseDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertDeleteLicense.value);
    const licensesToDelete = useSelector((state) => state.user.licensesToDelete);
    const handleClose = () => {
        store.dispatch(setLicensesToDelete([]));
        store.dispatch(openAlertDeleteLicenseDialog(false));
    };
    const handleDeleteLicenses = async () => {
        handleClose();
        await store.dispatch(removeUsersFromSubscription(licensesToDelete));
        store.dispatch(removeSharedUsersFromGroups({usersIds: licensesToDelete}));
        store.dispatch(getSubscriptionData())
        await store.dispatch(
            showNotification({
                type: 'success',
                message: `License${licensesToDelete?.length > 1 ? 's' : ''} deleted`,
            })
        );
        store.dispatch(setSelectedLicenses([]));
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-licenses-dialog-title"
            aria-describedby="alert-delete-licenses-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Remove
                            license<span>{licensesToDelete?.length > 1 && 's'}</span></Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    noWrap
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginBottom: 1,
                    }}
                >
                    Are you sure you want to
                    delete <span>{licensesToDelete?.length === 1 && 'the'}</span> license{licensesToDelete?.length > 1 &&
                <span>s</span>}?
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleDeleteLicenses}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Delete</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
