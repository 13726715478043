import {useSelector} from "react-redux";
import {Container, Stack, SvgIcon, Typography} from "@mui/material";
import * as React from "react";

export default function PageNotAvailable() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const subscription = useSelector((state) => state.user.subscription);
    const billingEmail = useSelector((state) => state.user.billingEmail);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    if (!subscriptionInitialized || subscription.status !== 2) return null;
    return (
        <Container disableGutters sx={{width: '600px'}}>
            <Stack
                spacing={3}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: domainInstallAlert ? 'calc(100vh - 64px)' : 'calc(100vh - 64px - 72px - 64px - 1px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SvgIcon sx={{width: 48, height: 48, marginBottom: 2}}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M38 8H10C7.78 8 6 9.8 6 12V36C6 38.2 7.78 40 10 40H38C40.2 40 42 38.2 42 36V12C42 9.8 40.22 8 38 8ZM38 36H10V16H38V36Z"
                            fill="black" fillOpacity="0.54"/>
                        <path
                            d="M17 30.88L19.12 33L24 28.12L28.88 33L31 30.88L26.12 26L31 21.12L28.88 19L24 23.88L19.12 19L17 21.12L21.88 26L17 30.88Z"
                            fill="black" fillOpacity="0.54"/>
                    </svg>
                </SvgIcon>
                <Stack spacing={1}
                       sx={{
                           display: 'flex',
                           width: '100%',
                           // minHeight: domainInstallAlert ? 'calc(100vh - 64px)' : 'calc(100vh - 64px - 72px - 64px - 1px)',
                           alignItems: 'center',
                           justifyContent: 'center',
                       }}>
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: "Open Sans",
                        fontSize: 20,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '28px',
                        letterSpacing: '-0.02em',
                    }}>This page is not available</Typography>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.02em',
                    }}>
                    This page is available only to the subscription manager
                </Typography>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: "Open Sans",
                        fontSize: 16,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.02em',
                    }}>
                    <a href={`mailto:${billingEmail}`}>{billingEmail}</a>
                </Typography>
                </Stack>
            </Stack>
        </Container>
    )
}
