import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {CloseOutlined} from "@mui/icons-material";

// project import
import store from "../../store/store";
import {openSwitchToAnnualDialog} from "../../store/reducers/dialogSlice";
import {subscriptionChangeLicensesToAnnual} from "../../store/reducers/userSlice";

export default function SwitchToAnnualDialog() {
    const dispatch = useDispatch();
    const subscription = useSelector((state) => state.user.subscription);
    const open = useSelector((state) => state.dialogsOpen.switchToAnnual.value);
    const amountChanges = useSelector((state) => state.user.amountChanges);
    const currencySign = subscription.subscription?.currency === 'EUR' ? '€' : '$';
    const changeLicensesToAnnualStatus = useSelector((state) => state.user.changeLicensesToAnnualStatus);
    const loading = changeLicensesToAnnualStatus === 'loading';
    const handleClose = async () => {
        store.dispatch(openSwitchToAnnualDialog(false));
    };
    const handleClickSwitchToAnnual = async () => {
        await dispatch(subscriptionChangeLicensesToAnnual({
            subscriptionId: subscription.subscription_id
        }));
        await handleClose();
    };
    const getNextPaymentDate = () => {
        const date = new Date();
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleDateString("en-US", options)
    }
    const nextPaymentDate = getNextPaymentDate();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-switch-to-annual-dialog-title"
            aria-describedby="alert-switch-to-annual-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    maxWidth: 840,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Change subscription</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginTop: 1,
                    }}
                >
                    You will be
                    billed {currencySign}{amountChanges.pay_now} on {nextPaymentDate} and {currencySign}{amountChanges.next_payment} every
                    1 year after.
                </Typography>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <LoadingButton loading={loading} onClick={handleClickSwitchToAnnual} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>
                    <Typography variant={'dialogButton'}>Change to Annual</Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
