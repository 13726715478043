import {styled} from "@mui/material/styles";
import {Autocomplete, Avatar, Box, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import * as React from "react";
import {SearchOutlined, CloseOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// project import
import store from "../../store/store";
import {getFullContact, setSearchContactsFilter} from "../../store/reducers/contactsSlice";
import {openEditContactDialog} from "../../store/reducers/dialogSlice";

export default function SearchContacts() {
    const useFindPath = () => {
        const location = useLocation();
        const [currentPath, setCurrentPath] = useState();
        useEffect(() => {
            setCurrentPath(location.pathname);
        }, [location]);
        return currentPath;
    };
    const currentPath = useFindPath();
    return currentPath && currentPath === '/' && <AutocompleteStyled/>;
}

function AutocompleteStyled() {
    let contacts = useSelector((state) => state.contacts.contacts);
    contacts = contacts.map(item => ({...item, type: 'Contacts'}));
    const filterOptions = (options, {inputValue}) => options.filter(option => (option.name && option.name.toLowerCase().includes(inputValue.toLowerCase())) || (option.email && option.email.toLowerCase().includes(inputValue.toLowerCase())));
    const [inputValue, setInputValue] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (inputValue.length > 0) {
            setOpen(true);
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        setOpen(newInputValue?.length > 0);
    };
    const onChange = (event, user) => {
        store.dispatch(getFullContact(user.id));
        store.dispatch(openEditContactDialog(true));
    }
    return (
        <Stack sx={{
            width: 720,
            minWidth: 480,
            height: 48,
            justifyContent: 'center',
        }}>
            <Autocomplete
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={onChange}
                freeSolo
                disableClearable
                autoHighlight
                options={contacts}
                getOptionLabel={(option) => option.name || option.email || ''}
                filterOptions={filterOptions}
                renderInput={(params) => {
                    const {InputLabelProps, InputProps, ...rest} = params;
                    return <StyledTextField
                        sx={{
                            width: '100%',
                            height: 42,
                            display: "flex",
                            justifyContent: "center",
                        }}
                        id="input-with-icon-textfield"
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{marginLeft: '6px',}}>
                                    <SearchOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                inputValue && <InputAdornment position="end">
                                    <CloseOutlined
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            store.dispatch(setSearchContactsFilter({searchContactsFilter: {}}));
                                            setInputValue('')
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                height: '42px',
                                width: '100%',
                                color: 'rgba(32, 33, 36, 1)',
                                fontSize: '16px',
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '46px',
                                letterSpacing: '-0.32px',
                            }
                        }}
                        {...params.InputProps} {...rest}
                    />
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {flexShrink: 0}}} {...props}
                         key={option.id || option.resource}>
                        <Stack direction={'row'} spacing={2} alignItems="center" justifyContent="center">
                            <Avatar sx={{width: 28, height: 28}} src={option.photo}/>
                            <Stack direction={'row'} spacing={'10.5px'}>
                                <Typography variant={'settingsMenu'}>{option.name || option.email}</Typography>
                                <EmailSection option={option}/>
                            </Stack>
                        </Stack>
                    </Box>
                )}
            />
        </Stack>
    );
}

const EmailSection = ({option}) => {
    if (!option.email) return null;
    return (
        <>
            <Typography variant={'settingsMenu'} color={'rgba(32, 33, 36, 0.54)'}>&bull;</Typography>
            <Typography variant={'settingsMenu'} color={'rgba(32, 33, 36, 0.54)'}>{option.email}</Typography>
        </>
    )
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));
