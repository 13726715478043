import {Alert, Button, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {MonetizationOnOutlined} from "@mui/icons-material";
import * as React from "react";

// project import

export default function LegacyPlanAlert() {
    const subscription = useSelector((state) => state.user.subscription);
    if (Object.keys(subscription).length === 0 || !subscription.subscription.version || subscription.subscription.version >= 2) return null;
    const message = 'You’re on the legacy plan. To check new pricing click on the button below';
    return (
        <Alert
            sx={{
                width: 1368, height: 48, borderRadius: '4px',
                background: 'rgba(230, 243, 250, 1)',
                '& .MuiAlert-icon': {
                    color: 'rgba(59, 125, 237, 1)'
                }
            }}
            severity="info"
        >
            <Typography
                sx={{
                    color: 'rgba(1, 54, 84, 1)',
                    fontSize: 16,
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    letterSpacing: '0.17px',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                }}
            >
                {message}
            </Typography>
        </Alert>
    )
}

const RenderButton = () => {
    return (
        <Button color="inherit" startIcon={<MonetizationOnOutlined/>}
                sx={{height: '30px', marginTop: '-1px'}}
            // onClick={handleClickDomainInstall}
        >
            <Typography
                sx={{
                    color: 'rgba(95, 43, 1, 1)',
                    fontSize: 13,
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                }}
            >
                manage subscription
            </Typography>
        </Button>
    )
}
