import {Box, Container} from "@mui/material";
import Stack from "@mui/material/Stack";
import {Link, useRouteError} from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

export default function FallbackError() {
    const error = useRouteError();
    console.error(error);
    if (window.bugsnagUserId) {
        Bugsnag.setUser(window.bugsnagUserId, window.bugsnagUserEmail)
    }
    Bugsnag.notify(error)
    if (window.stackdriverErrorReporter) {
        window.stackdriverErrorReporter.setUser(window.bugsnagUserEmail)
        window.stackdriverErrorReporter.report(error);
    }
    return (
        <Container disableGutters maxWidth="sm">
            <Stack sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box
                    sx={{
                        width: 400,
                        height: 400,
                        display: 'inline-flex',
                        padding: '26px 40px 24px 40px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '32px',
                        borderRadius: '6px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        background: 'rgba(255, 255, 255, 0.08)',
                    }}
                >
                    <Stack spacing={1} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div>Something went wrong</div>
                        <Link to={process.env.REACT_APP_PUBLIC_URL} style={{color: 'rgba(59, 125, 237, 1)'}}>
                            Home
                        </Link>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    )
}
